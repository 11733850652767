import platformAPI from './platformAPI';

export function createSubscription(productName) {
  return platformAPI.post('/v1/subscriptions', { product_name: productName });
}

export function cancelSubscription(productName, cancelMotive) {
  return platformAPI.post('/v1/subscriptions/cancel', {
    product_name: productName,
    cancel_motive: cancelMotive,
  });
}

export function endSubscriptionTrial(productName) {
  return platformAPI.post('/v1/subscriptions/end_trial', {
    product_name: productName,
  });
}

export function selectPlan(productName, planId, metadata) {
  return platformAPI.post('/v1/subscriptions/select_plan', {
    product_name: productName,
    plan_id: planId,
    metadata,
  });
}
