// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorMessage = (inputName: string, errors: any, touched: any) => {
  if (inputName.includes('.')) {
    const splitedInputName = inputName.split('.');
    const firstKey = splitedInputName[0];
    const secondKey = splitedInputName[1];

    if (!touched[firstKey] || !touched[firstKey][secondKey]) {
      return undefined;
    }
    if (errors[firstKey] && errors[firstKey][secondKey]) {
      return errors[firstKey][secondKey];
    }
    return undefined;
  }
  if (errors[inputName]) {
    return errors[inputName];
  }
  return undefined;
};
