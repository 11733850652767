type Dictionary<K> = Record<string, K>;

interface Config {
  authUrl: string;
  cardFrontUrl: string;
  conciliationFrontUrl: string;
  contactEmail: string;
  landingUrl: string;
  mixpanelToken: string;
  payApiUrl: string;
  payFrontUrl: string;
  payProductId: string;
  platformApiUrl: string;
  rollbarToken: string;
  selfUrl: string;
}

const config: Dictionary<Config> = {
  dev: {
    authUrl: 'http://localhost:5100',
    cardFrontUrl: 'http://localhost:3180',
    conciliationFrontUrl: 'http://localhost:6180',
    contactEmail: 'contacto@increase.app',
    landingUrl: 'https://staging.increase.app',
    mixpanelToken:
      import.meta.env.REACT_APP_EVENTS_MIXPANEL_TOKEN || '6a0697c73a29ddfc6736a34574440b9f',
    payApiUrl: 'https://gateway.staging.increase.app/pay',
    payFrontUrl: 'https://pay.staging.increase.app',
    payProductId: '21a18cfc-724f-45ab-9afe-8775e3c782b3',
    platformApiUrl: 'http://localhost:10000/platform',
    rollbarToken: 'e49144b4a8924c0a8136b149575fb598',
    selfUrl: 'http://localhost:5380',
  },
  staging: {
    authUrl: 'https://auth.staging.increase.app',
    cardFrontUrl: 'https://card.staging.increase.app',
    conciliationFrontUrl: 'https://conciliation.staging.increase.app',
    contactEmail: 'contacto@increase.app',
    landingUrl: 'https://staging.increase.app',
    mixpanelToken:
      import.meta.env.REACT_APP_EVENTS_MIXPANEL_TOKEN || '6a0697c73a29ddfc6736a34574440b9f',
    payApiUrl: 'https://gateway.staging.increase.app/pay',
    payFrontUrl: 'https://pay.staging.increase.app',
    payProductId: '21a18cfc-724f-45ab-9afe-8775e3c782b3',
    platformApiUrl: 'https://gateway.staging.increase.app/platform',
    rollbarToken: 'e49144b4a8924c0a8136b149575fb598',
    selfUrl: 'https://platform.staging.increase.app',
  },
  playground: {
    authUrl: 'https://auth.playground.increase.app',
    cardFrontUrl: 'https://card.playground.increase.app',
    conciliationFrontUrl: 'https://conciliation.staging.increase.app',
    contactEmail: 'contacto@increase.app',
    landingUrl: 'https://staging.increase.app',
    mixpanelToken:
      import.meta.env.REACT_APP_EVENTS_MIXPANEL_TOKEN || '6a0697c73a29ddfc6736a34574440b9f',
    payApiUrl: 'https://gateway.playground.increase.app/pay',
    payFrontUrl: 'https://pay.playground.increase.app',
    payProductId: '21a18cfc-724f-45ab-9afe-8775e3c782b3',
    platformApiUrl: 'https://gateway.playground.increase.app/platform',
    rollbarToken: 'e49144b4a8924c0a8136b149575fb598',
    selfUrl: 'https://platform.playground.increase.app',
  },
  production: {
    authUrl: 'https://auth.increase.app',
    cardFrontUrl: 'https://card.increase.app',
    conciliationFrontUrl: 'https://v2.conciliation.increase.app',
    contactEmail: 'contacto@increase.app',
    landingUrl: 'https://increase.app',
    mixpanelToken:
      import.meta.env.REACT_APP_EVENTS_MIXPANEL_TOKEN || 'e177dab63126395e646fc9ab7d9fb1d8',
    payApiUrl: 'https://gateway.increase.app/pay',
    payFrontUrl: 'https://pay.increase.app',
    payProductId: '3dbb8205-c57e-400b-861f-bc1ada61f8e2',
    platformApiUrl: 'https://gateway.increase.app/platform',
    rollbarToken: 'e49144b4a8924c0a8136b149575fb598',
    selfUrl: 'https://platform.increase.app',
  },
};

const currentConfig = config[import.meta.env.VITE_ENV || 'dev'];

export default currentConfig;
export { currentConfig as config };
