import { useEffect } from 'react';
import { useErrorTracker } from '@increasecard/increase-core';

export function ErrorTrackerLoader() {
  const tracker = useErrorTracker();

  useEffect(() => {
    if (!window.errorTracker && tracker) {
      window.errorTracker = tracker;
    }
  }, [tracker]);

  return null;
}

/**
 * Reports an error using our errorTracker
 */
export const AVAILABLE_TYPES = ['error', 'critical', 'warning', 'info', 'debug'];
export function reportError(message = '', data = {}, type = 'error') {
  if (!AVAILABLE_TYPES.includes(type)) {
    console.error(`reportError: wrong error type: ${type}`);
    return;
  }
  if (!window.errorTracker) {
    console.warn(
      `reportError: error tracker not found, couldn't report message:${message} data:${data} type:${type}`,
    );
    return;
  }
  window.errorTracker[type](message, data);
}
