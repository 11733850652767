import * as Yup from 'yup';

export default Yup.object({
  cardholder_name: Yup.string().required('Campo obligatorio'),
  cardholder_document: Yup.string()
    .matches(/^\d+$/, 'Debe contener sólo números')
    .required('Campo obligatorio'),
  number: Yup.string()
    .min(12, 'Debe tener al menos 12 dígitos')
    .max(19, 'Debe tener menos de 20 dígitos')
    .matches(/^\d+$/, 'Debe contener solo números')
    .required('Campo obligatorio'),
  expiration_month: Yup.string()
    .matches(/^\d{2}$/, 'Debe consistir de dos dígitos')
    .required('Campo obligatorio'),
  expiration_year: Yup.string()
    .matches(/^\d{2}$/, 'Debe consistir de dos dígitos')
    .required('Campo obligatorio'),
});
