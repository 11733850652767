import { Grid, InputText } from '@increasecard/typed-components';

export interface CbuShowProps {
  metadata: {
    number: string;
  };
}

export default function CbuShow({ metadata }: CbuShowProps) {
  return (
    <Grid>
      <InputText
        className="grid-span-desktop-6"
        label="Número de CBU"
        readOnly
        value={metadata.number}
      />
    </Grid>
  );
}
