import { Button, Link } from '@increasecard/typed-components';
import styled from 'styled-components';
import { ArrowLeftIcon } from '@increasecard/icons';

export const PrimaryButton = styled(Button)`
  width: 100%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colorsNew.increase.regular};
  transition: all ease-in-out 0.3s;
  :not(:disabled):not(:active):hover {
    background-color: ${({ theme }) => theme.colorsNew.increase.dark};
    transition: all ease-in-out 0.3s;
    box-shadow:
      0px 9px 9px rgba(24, 32, 38, 0.09),
      0px 3px 6px rgba(24, 32, 38, 0.18) !important;
  }
  :not(:disabled):active {
    background-color: ${({ theme }) => theme.colorsNew.increase.dark};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: fit-content;
  }
`;

export const InvisibleButton = styled(Button)`
  background: transparent;
  color: ${({ theme }) => theme.colorsNew.increase.regular};
  :not(:disabled):hover {
    background: rgba(1, 167, 123, 0.07);
  }
  :not(:disabled):active {
    color: ${({ theme }) => theme.colorsNew.increase.dark};
  }
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colorsNew.increase.dark70};
`;
const StyledArrowIcon = styled(ArrowLeftIcon)`
  fill: ${({ theme }) => theme.colorsNew.increase.dark70} !important;
`;

export const BackButton = ({ onClick, label }: { onClick: () => void; label: string }) => {
  return (
    <InvisibleButton buttonType="invisible" onClick={onClick} icon={<StyledArrowIcon />}>
      <StyledSpan>{label}</StyledSpan>
    </InvisibleButton>
  );
};

export const LinkButton = styled(Link)`
  color: ${({ theme }) => theme.colorsNew.increase.regular};
  :not(:disabled):hover {
    color: ${({ theme }) => theme.colorsNew.increase.dark};
  }
  :not(:disabled):active {
    color: ${({ theme }) => theme.colorsNew.increase.dark};
  }
`;
