import { useState, useEffect } from 'react';
import { updateBillingSettings } from '../services/BillingService';
import { getActiveCompany } from '../services/UserService';
import { useCountry } from '@increasecard/increase-core';

const EMPTY_BILLING_SETTINGS = {};

export function useBillingSettings() {
  const [billingSettings, setBillingSettings] = useState(null);
  const country = useCountry();
  useEffect(() => {
    getActiveCompany(country).then((company) => {
      if (!company) {
        setBillingSettings(EMPTY_BILLING_SETTINGS);
        return;
      }
      setBillingSettings(company);
    });
  }, [country]);
  return [
    billingSettings,
    async (values) => {
      await updateBillingSettings(values, country);
      setBillingSettings(values);
    },
  ];
}
