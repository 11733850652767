import client from './platformAPI';
import { User, UserApiFields, userMapper } from '@increasecard/increase-core';
import { Company } from '../models/Company';

export function getMe(): Promise<User> {
  return client.get('/v1/users/me').then((response) => response.data);
}

export function getUser(id: string): Promise<User> {
  return client.get(`/v1/users/${id}`).then((response) => response.data.data);
}

export function setUserSettings(id: string, values: User): Promise<User> {
  return client.put(`/v1/users/${id}`, values);
}

export function deleteUser(id: string): Promise<void> {
  return client.delete(`/v1/users/${id}`);
}

export function getUsers(): Promise<User[]> {
  return client
    .get('/v1/users')
    .then((response) => response.data.data.map((d: UserApiFields) => userMapper(d)));
}

export function getActiveCompany(country: string): Promise<Company> {
  return client
    .get('/v1/companies/get_active', { headers: { Tenant: country } })
    .then((response) => response.data);
}
