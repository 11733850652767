import { useState, useEffect } from 'react';
import { getUser, setUserSettings as updateUserSettingsAPI } from '../services/UserService';

const EMPTY_USER_SETTINGS = {};

export function useUserSettings(id) {
  const [userSettings, setUserSettings] = useState(null);

  const updateUserSettings = async (id, values) => {
    const updatedUser = await updateUserSettingsAPI(id, values);
    setUserSettings(updatedUser.data);
  };

  useEffect(() => {
    getUser(id).then((user) => {
      if (!user) {
        setUserSettings(EMPTY_USER_SETTINGS);
        return;
      }
      setUserSettings(user);
    });
  }, [id]);

  return { userSettings, updateUserSettings };
}
