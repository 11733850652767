import styled from 'styled-components';

import { Settings } from '../icons/icons';
import Icon from '../components/Icon';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brandWhite.regular};
  color: ${({ theme }) => theme.colors.brandBlue.regular};
  .animated-image {
    height: 110px;
    width: 110px;
    animation: spin 5s infinite linear;
  }
  h1 {
    text-align: center;
    font-size: 20px;
    max-width: 500px;
    margin: 0;
    font-weight: 400;
  }
  p {
    font-size: 45px;
    text-align: center;
    font-weight: 300;
    max-width: 650px;
    line-height: 68px;
    margin: 42px 0 26px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Maintenance = () => {
  return (
    <Content>
      <Icon className="animated-image" src={Settings} />
      <p>Ya volvemos, estamos haciendo tareas de mantenimiento</p>
      <h1>
        Estamos realizando mejoras para brindarte un servicio óptimo. Te pedimos disculpas por el
        inconveniente.
      </h1>
    </Content>
  );
};

export default Maintenance;
