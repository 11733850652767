import PlacetoPayShow, { PlacetoPayShowProps } from './PlacetoPayShow';
import CreditCardShow, { CreditCardShowProps } from './CreditCardShow';
import CbuShow, { CbuShowProps } from './CbuShow';

export interface PaymentMethodShowProps {
  paymentMethod:
    | ({
        type: 'PaymentMethods::Placetopay';
      } & PlacetoPayShowProps)
    | ({
        type: 'PaymentMethods::CreditCard';
      } & CreditCardShowProps)
    | ({
        type: 'PaymentMethods::Cbu';
      } & CbuShowProps);
}

export default function PaymentMethodShow({ paymentMethod }: PaymentMethodShowProps) {
  switch (paymentMethod.type) {
    case 'PaymentMethods::Placetopay':
      return <PlacetoPayShow {...paymentMethod} />;
    case 'PaymentMethods::CreditCard':
      return <CreditCardShow {...paymentMethod} />;
    case 'PaymentMethods::Cbu':
      return <CbuShow {...paymentMethod} />;
    default:
      return null;
  }
}
