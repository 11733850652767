import { H3, Link, Paragraph } from '@increasecard/typed-components';
import styled from 'styled-components';
import { Container } from '../components/Containers';
import SectionTitle from '../components/SectionTitle';

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export function Support() {
  return (
    <Container style={{ minHeight: 'calc(100vh - 112px)', paddingTop: '45px' }}>
      <SectionTitle>Nuestros canales de contacto</SectionTitle>
      <Stack>
        <H3>Para consultas sobre Pay</H3>
        <Paragraph>
          <span aria-label="emoji celular" role="img">
            📲
          </span>{' '}
          Whatsapp:{' '}
          <Link
            href="https://api.whatsapp.com/send/?phone=541128635255&text&app_absent=0"
            target="_blank"
          >
            +54 9 11 2863-5255
          </Link>
        </Paragraph>
        <Paragraph>
          <span aria-label="emoji carta" role="img">
            📩
          </span>{' '}
          <Link href="mailto:soporte.pay@increase.app" target="_blank">
            soporte.pay@increase.app
          </Link>
        </Paragraph>
      </Stack>
      <Stack>
        <H3>Para consultas sobre Card</H3>
        <Paragraph>
          <span aria-label="emoji celular" role="img">
            📲
          </span>{' '}
          Whatsapp:{' '}
          <Link
            href="https://api.whatsapp.com/send/?phone=541162927242&text&app_absent=0"
            target="_blank"
          >
            +54 9 11 6292-7242
          </Link>
        </Paragraph>
        <Paragraph>
          <span aria-label="emoji carta" role="img">
            📩
          </span>{' '}
          <Link href="mailto:soporte@increase.app" target="_blank">
            soporte@increase.app
          </Link>
        </Paragraph>
      </Stack>
    </Container>
  );
}
