import { useEffect, useState } from 'react';
import { useCountry } from '@increasecard/increase-core';
import { getAvailablePaymentMethodTypes } from '../services/PaymentMethodsService';
import { PAYMENT_METHODS } from '../containers/SettingsBilling.jsx';

function filterCreditCardType(types) {
  return types.filter((type) => !type.toLowerCase().includes('creditcard'));
}

export default function useAvailablePaymentMethodTypes() {
  const [paymentMethodTypes, setPaymentMethodTypes] = useState(null);
  const country = useCountry();
  useEffect(() => {
    getAvailablePaymentMethodTypes(country)
      .then((types) => {
        // (dbelay) filter credit card option in case the API sends it [PL-184]
        types = filterCreditCardType(types);
        setPaymentMethodTypes(types.map((type) => PAYMENT_METHODS[type]));
      })
      .catch(() =>
        setPaymentMethodTypes([
          {
            type: 'PaymentMethods::Placetopay',
            label: 'Tarjeta de crédito (a través de Placetopay)',
          },
        ]),
      );
  }, [country]);
  return paymentMethodTypes;
}
