import { useTranslation } from 'react-i18next';
import { NativeSelect, NativeSelectProps } from '@increasecard/typed-components';
import { PROVINCES_BY_COUNTRY } from './Provinces';

export interface ProvinceSelectProps extends NativeSelectProps {
  country: keyof typeof PROVINCES_BY_COUNTRY;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ProvinceSelect({ country, name, label, ...rest }: ProvinceSelectProps) {
  const options = PROVINCES_BY_COUNTRY[country];
  const { t } = useTranslation('common');
  return (
    <NativeSelect id={name} label={t('province')} name={name} {...rest}>
      <option defaultChecked>{t('chooseProvince')}</option>
      {options &&
        options.map((opt) => (
          <option key={opt} value={opt}>
            {opt}
          </option>
        ))}
    </NativeSelect>
  );
}
