import { useState, useEffect } from 'react';
import { getMe, setUserSettings as updateMeSettingsAPI } from '../services/UserService';

const EMPTY_USER_SETTINGS = {};

export function useMeSettings() {
  const [meSettings, setMeSettings] = useState(null);

  const updateMeSettings = async (id, values) => {
    await updateMeSettingsAPI(id, values);
    setMeSettings(values);
  };

  useEffect(() => {
    getMe().then((user) => {
      if (!user) {
        setMeSettings(EMPTY_USER_SETTINGS);
        return;
      }
      setMeSettings(user);
    });
  }, []);

  return { meSettings, updateMeSettings };
}
