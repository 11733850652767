import React, { useEffect, DetailsHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface ModalProps extends DetailsHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  hidden?: boolean;
}

function Modal({ children, className, hidden, ...rest }: ModalProps) {
  useEffect(() => {
    if (!hidden) {
      window.scrollTo(0, 0);
    }
  }, [hidden]);
  return (
    <div className={className} hidden={hidden} {...rest}>
      <section role="dialog">{children}</section>
    </div>
  );
}

export default styled(Modal)`
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1002;

  section {
    background-color: white;
  }

  &[hidden] {
    display: none;
  }
`;
