import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { reportError } from '../components/ErrorTracker';

export type LangCode = 'es' | 'es-AR' | 'es-CL' | 'es-CO' | 'es-DO' | 'es-EC' | 'es-PE' | 'es-UY';

const NAMESPACES: string[] = ['api', 'common', 'billing', 'trialEnd', 'resources'];

export default i18next
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng: 'es',
    ns: NAMESPACES,
    defaultNS: 'common',
    fallbackLng: {
      'es-AR': ['es'],
      'es-CL': ['es'],
      'es-CO': ['es'],
      'es-DO': ['es'],
      'es-EC': ['es'],
      'es-PE': ['es'],
      'es-UY': ['es'],
      default: ['es'],
    },
    debug: ['dev', 'staging', 'playground'].includes(`${import.meta.env.VITE_ENV}`),
    backend: {
      loadPath: '/locales-1/{{lng}}/{{ns}}.json',
    },
  });

export function setLocale(locale: LangCode) {
  i18next.changeLanguage(locale);
}

export function strictTranslate(key: string, metadata = {}, fallbackKey: string) {
  if (!i18next.exists(key)) {
    const keyNotFoundMessage = `Translate key not found: ${key}`;
    reportError(keyNotFoundMessage, metadata);
    if (fallbackKey) {
      return i18next.t(fallbackKey, metadata);
    }
    return keyNotFoundMessage;
  }

  return i18next.t(key, metadata);
}
