import platformAPI from './platformAPI';
import { createSubscription } from './SubscriptionService';

export function getProducts() {
  return platformAPI.get('/v1/products').then((response) => response.data.products);
}

export function acquireProduct(product) {
  return createSubscription(product.name).then(() => {
    window.location.href = product.uri;
  });
}

export function getPrice({ productName, country, units }) {
  return platformAPI
    .get('/v1/prices/estimate', {
      params: { product_name: productName, units },
      headers: { Tenant: country },
    })
    .then((response) => response.data);
}
