import {
  Table,
  Currency,
  TableRow,
  Th,
  Td,
  THead,
  TableWrapper,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';

export interface InvoiceDetailTableProps {
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  locale?: string;
  currency?: string;
}

export default function InvoiceDetailTable({
  quantity,
  unitPrice,
  totalPrice,
  locale = 'es-AR',
  currency = 'ARS',
}: InvoiceDetailTableProps) {
  const { t } = useTranslation('invoiceDetail');
  return (
    <TableWrapper>
      <Table>
        <THead>
          <TableRow>
            <Th rightAligned>{t('totalBalance')}</Th>
            <Th rightAligned>{t('POSCount')}</Th>
            <Th rightAligned>{t('pricePerTerminal')}</Th>
          </TableRow>
        </THead>
        <tbody>
          <TableRow>
            <Td rightAligned>
              <strong>
                <Currency currency={currency} language={locale} value={totalPrice} />
              </strong>
            </Td>
            <Td rightAligned>{quantity}</Td>
            <Td rightAligned>
              <Currency currency={currency} language={locale} value={unitPrice} />
            </Td>
          </TableRow>
        </tbody>
      </Table>
    </TableWrapper>
  );
}
