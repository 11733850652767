import React, { useState } from 'react';
import { MainContent, ContentWrapper } from '../components/Containers';
import SectionTitle from '../components/SectionTitle';
import SettingsSidebar from '../components/SettingsSidebar';
import BillingSettingsForm from '../components/BillingSettingsForm';
import { useBillingSettings } from '../hooks/useBillingSettings';
import LoadingIndicator from '../components/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import InlineAlert from '../components/InlineAlert';
import { useUser } from '@increasecard/increase-core';
import { ApiErrorMessage } from '../components/ApiErrorMessage';

export const PAYMENT_METHODS = {
  'PaymentMethods::Cbu': {
    type: 'PaymentMethods::Cbu',
    labelKey: 'billing:paymentCBU',
  },
  'PaymentMethods::CreditCard': {
    type: 'PaymentMethods::CreditCard',
    labelKey: 'billing:paymentCreditCard',
  },
  'PaymentMethods::Placetopay': {
    type: 'PaymentMethods::Placetopay',
    labelKey: 'billing:paymentPlacetopay',
  },
};

function SettingsBilling() {
  const user = useUser();
  const [apiError, setApiError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { t } = useTranslation('billing');
  const [billingSettings, setBillingSettings] = useBillingSettings();
  const country = user.defaultCountry.toLocaleLowerCase();

  const onSubmit = async (values) => {
    try {
      setApiError(null);
      setSuccessMessage(null);

      await setBillingSettings(values);

      setSuccessMessage(t('savedSuccessfully'));
    } catch (error) {
      setApiError(error);
    }
  };

  function onSuccessMessageClose() {
    setSuccessMessage(null);
  }

  const loadingBillingSettings = billingSettings === null || user === null;
  return (
    <ContentWrapper>
      <SettingsSidebar settings="invoices" />
      <MainContent>
        <SectionTitle>Datos de facturación</SectionTitle>
        <p>{t('billingSettingsDescription')}</p>
        {successMessage && (
          <InlineAlert
            message={successMessage}
            onClose={onSuccessMessageClose}
            style={{ marginTop: '8px' }}
            variant="info"
          />
        )}
        <ApiErrorMessage error={apiError} marginTop={40} />
        <div>
          <h2>{t('businessName')}</h2>
          {loadingBillingSettings ? (
            <LoadingIndicator />
          ) : (
            <BillingSettingsForm
              cancelText={t('cancelSubmitForm')}
              country={country}
              initialValues={billingSettings}
              onSubmit={onSubmit}
              submitText={t('submitForm')}
              taxIdAccount={user.account.tax_id}
              taxIdCompany={billingSettings.tax_id}
            />
          )}
        </div>
      </MainContent>
    </ContentWrapper>
  );
}

export default SettingsBilling;
