import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@increasecard/typed-components';
import { ContentWrapper } from '../components/Containers';
import ButtonGroup from '../components/ButtonGroup';
import { ApiErrorMessage } from '../components/ApiErrorMessage';
import InvoiceDetailTable from '../components/InvoiceDetailTable';
import { endSubscriptionTrial } from '../services/SubscriptionService';

const propTypes = {};

function TrialEndNoBilling({ className, priceToPay, history, match }) {
  const { t } = useTranslation('trialEnd');
  const [apiError, setApiError] = useState(null);
  const { productCodename } = match.params;

  const handleTrialEndSubmit = async () => {
    setApiError(null);
    try {
      await endSubscriptionTrial(productCodename);
      history.push('/trialEnd/success');
    } catch (error) {
      setApiError(error);
    }
  };

  return (
    <ContentWrapper className={className}>
      <h1>{t('important')}</h1>
      <p>
        <strong>{t('trialFinished')}</strong>
      </p>
      <p>A partir de ahora se te cobrará:</p>
      <InvoiceDetailTable
        quantity={priceToPay.quantity}
        totalPrice={priceToPay.total}
        unitPrice={priceToPay.unit_price}
      />
      <p>
        Este precio se verá reflejado en la factura correspondiente a CARDNET y el cobro se
        realizará por el mismo medio que actualmente tienes con CARDNET
      </p>
      <ApiErrorMessage error={apiError} marginTop={8} />
      <br />
      <ButtonGroup>
        <Button onClick={handleTrialEndSubmit} type="button">
          Ok quiero seguir
        </Button>
        <Button buttonType="invisible" type="button">
          No, gracias
        </Button>
      </ButtonGroup>
    </ContentWrapper>
  );
}

TrialEndNoBilling.propTypes = propTypes;

export default TrialEndNoBilling;
