import React from 'react';
import { Formik, Form, FormikTouched, FormikErrors } from 'formik';
import { Grid, InputText, Caption } from '@increasecard/typed-components';
import { PrimaryButton } from './Buttons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MeSettingsSchema from '../schemas/MeSettingsSchema';

function sanitizeInitialValues(initialValues: Partial<Values>): Values {
  return {
    first_name: initialValues.first_name || '',
    last_name: initialValues.last_name || '',
    email: initialValues.email || '',
    phone_number: initialValues.phone_number || '',
  };
}

export interface Values {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export interface SettingsMeFormProps {
  initialValues: Values;
  onSubmit: (values: Values) => void;
  submitText: string;
}

const StyledCaption = styled(Caption)`
  color: ${(props) => props.theme.colorsNew.primaryAction.regular};
`;

const errorMessage = (
  inputName: keyof Values,
  errors: FormikErrors<Values>,
  touched: FormikTouched<Values>,
) => {
  if (!touched[inputName]) {
    return undefined;
  }
  if (errors[inputName]) {
    return errors[inputName];
  }
  return undefined;
};

export default function SettingsMeForm({
  initialValues,
  onSubmit,
  submitText,
}: SettingsMeFormProps) {
  const { t } = useTranslation('settingsMe');
  return (
    <React.Fragment>
      <Formik
        initialValues={sanitizeInitialValues(initialValues)}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={MeSettingsSchema}
      >
        {({ values, isSubmitting, isValid, handleChange, errors, touched, handleBlur }) => (
          <React.Fragment>
            <Form onChange={handleChange}>
              <Grid rowGap={3}>
                <InputText
                  className="grid-span-desktop-6"
                  errorMessage={errorMessage('first_name', errors, touched)}
                  id="first_name"
                  label={t('firstName')}
                  name="first_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.first_name}
                />
                <InputText
                  className="grid-span-desktop-6"
                  errorMessage={errorMessage('last_name', errors, touched)}
                  id="last_name"
                  label={t('lastName')}
                  name="last_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.last_name}
                />
                <div>
                  <InputText
                    disabled
                    errorMessage={errorMessage('email', errors, touched)}
                    id="email"
                    label={t('email')}
                    name="email"
                    required
                    value={values.email}
                  />
                  <StyledCaption>{t('captionEmail')}</StyledCaption>
                </div>
                <div>
                  <InputText
                    errorMessage={errorMessage('phone_number', errors, touched)}
                    id="phone_number"
                    label={t('phoneNumber')}
                    name="phone_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.phone_number}
                  />
                  <StyledCaption>{t('captionPhone')}</StyledCaption>
                </div>
                <PrimaryButton disabled={isSubmitting || !isValid} type="submit">
                  {submitText}
                </PrimaryButton>
              </Grid>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
}
