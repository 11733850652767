import styled from 'styled-components';

export const MainContent = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-bottom: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 900px;
    margin-left: 63px;
    padding: 45px 0;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 430px;
    margin: 0;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  height: calc(100vh - 152px);
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 1100px;
  }
`;

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: white;
  height: 143px;
  border-top: 1px solid ${(props) => props.theme.colors.brandWhite.medium};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    height: 56px;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.colorsNew.gray.graySLight};
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 112px);
  padding-bottom: 143px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
    width: 100%;
    padding-bottom: 56px;
  }
`;

export default ContentWrapper;
