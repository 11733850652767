import { Spinner } from '@increasecard/typed-components';

export default function LoadingIndicator() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        height: '100%',
        width: '100%',
      }}
    >
      <Spinner size={64} />
    </div>
  );
}
