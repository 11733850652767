import styled from 'styled-components';
import { Link } from '@increasecard/typed-components';
import { FooterContainer } from './Containers';
import config from '../config';
import { useTranslation } from 'react-i18next';

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
    max-width: 1100px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  span {
    font-weight: 700;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
  a {
    margin: 20px auto 0 auto;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      margin: 0 0 0 27px;
    }
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <FooterContent>
        <span>© Click de pago</span>
        <LinkContainer>
          <Link
            href={`${config.landingUrl}${t('common:privacyPolicyPath')}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('common:privacyPolicy')}
          </Link>
          <Link
            href={`${config.landingUrl}${t('common:termsAndConditionsPath')}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('common:termsAndConditions')}
          </Link>
        </LinkContainer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
