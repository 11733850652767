import React, { useState, useEffect } from 'react';
import { SidebarLayout } from '../components/PaySettingsBilling/SidebarLayout';
import CenteredLayout from '../components/PaySettingsBilling/CenteredLayout';
import ResponsiveTitle from '../components/PaySettingsBilling/ResponsiveTitle';
import PaySettingsBillingForm from '../components/PaySettingsBilling/PaySettingsBillingForm';
import {
  Paragraph,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
} from '@increasecard/typed-components';
import { useUser } from '@increasecard/increase-core';
import { useBillingSettings } from '../hooks/useBillingSettings';
import usePaymentMethods from '../hooks/usePaymentMethods';
import useToggle from '../hooks/useToggle';
import LoadingIndicator from '../components/LoadingIndicator';
import { updatePaymentMethod, createPaymentMethod } from '../services/PaymentMethodsService';
import config from '../config';
import { selectPlan } from '../services/SubscriptionService';
import { usePayPlans } from '../components/PaySettingsBilling/usePayPlans';
import { ApiErrorMessage } from '../components/ApiErrorMessage';

function redirectToPay() {
  window.location.replace(config.payFrontUrl);
}

const PaySettingsBilling = () => {
  const user = useUser();
  const [apiError, setApiError] = useState(null);
  const [billingSettings, setBillingSettings] = useBillingSettings();
  const [paymentMethods] = usePaymentMethods();
  const { value: adding, toggle: toggleAdding } = useToggle(
    paymentMethods && Array.isArray(paymentMethods) && paymentMethods.length < 1 ? true : false
  );
  const [plans, fetchPlans] = usePayPlans();
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const savePayPlan = (planId) => {
    const plan = plans.find((plan) => (plan.id = planId));
    const metadata = {
      name: plan.name,
      customer: billingSettings ? billingSettings.id : null, // id de la company activa
      account: user.account.id,
      product: plan.product.id,
    };
    return selectPlan('pay', planId, metadata);
  };

  const handleMakeActive = (paymentMethod) => {
    return updatePaymentMethod({
      id: paymentMethod.id,
      type: paymentMethod.type,
      active: true,
    });
  };

  const handleCreatePaymentMethod = (paymentMethod) => {
    if (paymentMethod.id) {
      delete paymentMethod['id'];
    }
    return createPaymentMethod(paymentMethod).then(() => {
      handleMakeActive(paymentMethod).then(() => {
        toggleAdding();
      });
    });
  };

  const resolvePaymentMethod = (paymentMethod) => {
    if (adding) {
      // users's first payment method
      return handleCreatePaymentMethod(paymentMethod);
    }
    const existingPaymentMethod = paymentMethods.find(
      (p) => p.metadata.number === paymentMethod.metadata.number
    );
    if (!existingPaymentMethod) {
      // it's a new payment method
      return handleCreatePaymentMethod(paymentMethod);
    }
    // payment method already saved, make active
    return handleMakeActive(existingPaymentMethod);
  };

  const saveFormData = async (values, overwriteTaxId) => {
    const paymentMethod = values.active_payment_method;
    setApiError(null);
    try {
      // save billing settings
      setBillingSettings(values, overwriteTaxId)
        .then(() => {
          // update / create payment method
          resolvePaymentMethod(paymentMethod)
            .then(() => {
              // save selected plan
              savePayPlan(values.planId)
                .then(() => {
                  // form saved -> redirect to pay
                  setSuccessModalOpen(true);
                  return;
                })
                .catch((error) => {
                  setApiError(error);
                });
            })
            .catch((error) => {
              setApiError(error);
            });
        })
        .catch((error) => {
          setApiError(error);
        });
    } catch (error) {
      setApiError(error);
    }
  };

  const onSubmit = async (values, overwriteTaxId) => {
    saveFormData(values, overwriteTaxId);
  };

  const onSuccessModalClose = () => {
    redirectToPay();
  };

  const noData = billingSettings === null || user === null || paymentMethods === null;

  return (
    <>
      <SidebarLayout>
        <CenteredLayout>
          {noData ? (
            <LoadingIndicator />
          ) : (
            <>
              <ResponsiveTitle>Completa los datos de facturación</ResponsiveTitle>
              <Paragraph style={{ margin: '8px 0 24px 0' }}>
                Con los siguientes datos emitiremos mensualmente tu factura y realizaremos el cobro
                recurrente de tu suscripción a Pay a partir del próximo mes.
              </Paragraph>
              <ApiErrorMessage error={apiError} marginTop={16} />
              <PaySettingsBillingForm
                country={user.defaultCountry}
                initialValues={billingSettings}
                onSubmit={onSubmit}
                plans={plans}
                pricingUrl={`${config.landingUrl}/precios/pay`}
                taxIdAccount={user.account.tax_id}
              />
              <Modal onClose={onSuccessModalClose} visible={successModalOpen}>
                <ModalHeader align="center">¡Felicitaciones!</ModalHeader>
                <ModalContent align="center">
                  Hemos registrado tus datos, ahora te vamos a redirigir al producto
                </ModalContent>
                <ModalFooter align="center">
                  <Button onClick={onSuccessModalClose}>Continuar</Button>
                </ModalFooter>
              </Modal>
            </>
          )}
        </CenteredLayout>
      </SidebarLayout>
    </>
  );
};

export default PaySettingsBilling;
