import { createContext } from 'react';

type Survey = {
  id: string;
  comment: string;
  product: { name: string };
};

export interface NpsState {
  surveys: Survey[] | null;
  removeSurvey?: (id: string) => void;
}

export const NpsContext = createContext<NpsState>({ surveys: null });
