import * as Yup from 'yup';

export default Yup.object({
  first_name: Yup.string().required('Campo obligatorio'),
  last_name: Yup.string().required('Campo obligatorio'),
  email: Yup.string().email('Email incorrecto').required('Campo obligatorio'),
  phone_number: Yup.string()
    .matches(/^\d*$/, 'Debe contener sólo números')
    .required('Campo obligatorio'),
});
