import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PlaceToPayCreateForm from './PlacetoPayCreateForm';
import CreditCardCreateForm from './CreditCardCreateForm';
import CbuCreateForm from './CbuCreateForm';
import { RadioButton, Grid } from '@increasecard/typed-components';
import { Field } from 'formik';

const SelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  label {
    flex: 1 0 45%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-wrap: nowrap;
    label {
      ${(props) => props.theme.typography.body}
      flex: 1;
      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }
`;

function PaymentMethodForm({ type, ...rest }) {
  switch (type) {
    case 'PaymentMethods::Placetopay':
      return <PlaceToPayCreateForm {...rest} />;
    case 'PaymentMethods::CreditCard':
      return <CreditCardCreateForm {...rest} />;
    case 'PaymentMethods::Cbu':
      return <CbuCreateForm {...rest} />;
    default:
      return null;
  }
}

function PaymentMethodInputGroup({ availablePaymentMethods, className, method, readOnly }) {
  const { t } = useTranslation('billing');
  return (
    <Grid className={className} rowGap={3}>
      {availablePaymentMethods.length > 1 && (
        <SelectorContainer>
          {availablePaymentMethods.map((m) => (
            <Field id={m.type} inline key={m.type} name="type" type="radio">
              {({ field: { onChange, onBlur, name } }) => {
                return (
                  <RadioButton
                    checked={m.type === method.type}
                    className="grid-span-desktop-4"
                    disabled={readOnly}
                    id={m.type}
                    label={t(m.labelKey)}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    type="radio"
                    value={m.type}
                  />
                );
              }}
            </Field>
          ))}
        </SelectorContainer>
      )}
      <PaymentMethodForm readOnly={readOnly} type={method.type} />
    </Grid>
  );
}

export default styled(PaymentMethodInputGroup)`
  margin-bottom: 30px;
`;
