import React from 'react';
import Footer from './Footer';
import PaymentMethodResultModal from './PaymentMethodResultModal';
import { routes } from '../routes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TFunction } from 'i18next';
import { History } from 'history';
import { ApplicationLayoutProps } from '@increasecard/typed-components';
import { Layout as ApplicationLayout } from '@increasecard/increase-core';
import { useCountry, Country } from '@increasecard/increase-core';

type LayoutProps = {
  children: React.ReactNode;
  contextNavEnabled?: boolean;
};

type TabList = ApplicationLayoutProps['tabs'];

const tabs = (history: History, t: TFunction, userCountry: Country): TabList => {
  const a: TabList = [
    {
      as: 'button',
      onClick: () => history.push(routes.platform.home),
      name: t('common:home'),
      active: history.location.pathname === routes.platform.home,
    },
    {
      as: 'button',
      onClick: () => history.push(routes.platform.settings.user),
      name: t('common:account'),
      active: history.location.pathname.startsWith(routes.platform.settings.user),
    },
    {
      as: 'button',
      onClick: () => history.push(routes.invoices.index),
      name: t('common:billing'),
      active: history.location.pathname.startsWith(routes.invoices.index),
    },
    {
      as: 'button',
      onClick: () => history.push(routes.advanced.index),
      name: t('common:advancedSettings'),
      active: history.location.pathname.startsWith(routes.advanced.index),
    },
  ];
  if (userCountry === 'ecuador') {
    a.push({
      as: 'button',
      onClick: () => history.push(routes.payments),
      name: t('common:payments'),
      active: history.location.pathname.startsWith(routes.payments),
    });
  }
  if (t('resources:myRequestsLink')) {
    a.push({
      as: 'button',
      onClick: () => history.push(routes.support),
      name: t('resources:myRequests'),
      active: history.location.pathname.startsWith(routes.support),
    });
  }
  return a;
};

export function Layout({ children, contextNavEnabled = true }: LayoutProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const userCountry = useCountry() || 'argentina';

  return (
    <ApplicationLayout tabs={contextNavEnabled ? tabs(history, t, userCountry) : null}>
      <PaymentMethodResultModal />
      {children}
      <Footer />
    </ApplicationLayout>
  );
}
