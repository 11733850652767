import styled from 'styled-components';
import Title from './Title';

const ResponsiveTitle = styled(Title)`
  font-size: 20px;
  letter-spacing: -0.04em;
  line-height: 124%;
  @media (min-width: 1100px) {
    margin: 24px 0 8px;
    font-size: 32px;
  }
`;

export default ResponsiveTitle;
