import { useState } from 'react';
import styled from 'styled-components';
import Product from '../components/Product';
import { Container } from '../components/Containers';
import InlineAlert from '../components/InlineAlert';
import EnterpriseMessage from '../components/EnterpriseMessage';
import { H2 } from '@increasecard/typed-components';
import Carousel from '../components/Carousel';

import {
  useCountry,
  useProducts,
  // useOnAcquire,
  useUser,
  Country,
} from '@increasecard/increase-core';
import LoadingIndicator from '../components/LoadingIndicator';

const Title = styled.p`
  margin: 40px auto 10px auto;
  text-align: center;
  color: ${({ theme }) => theme.colorsNew.increase.dark70};
  max-width: 70%;
  font-size: 22px;
  letter-spacing: -0.04em;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: fit-content;
  }
`;

const SubTitle = styled(H2)`
  margin: 0 auto;
  max-width: 340px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 100%;
  }
`;
const Platform = () => {
  const user = useUser();
  const country = useCountry();
  const products = useProducts();
  const [error, setError] = useState<string | null>(null);
  // const onAcquire = useOnAcquire();

  if (products === null || user === null) return <LoadingIndicator />;

  const productsWithEnabled = products.map((product) => {
    const activeProductsByCountry = user.activeProducts()[country as Country];
    const enabled =
      product.is_free ||
      (activeProductsByCountry && activeProductsByCountry.includes(product.name));
    return { product, enabled };
  });

  const someEnabledProductIsPaid = productsWithEnabled
    .filter(({ enabled }) => enabled)
    .some(({ product }) => !product.is_free);

  // const handleAcquire = (productName: string) => {
  //   const product = products.find((p) => p.name === productName);
  //   if (product === undefined) throw new Error(`Product ${productName} does not exist`);

  //   onAcquire(product).catch((error) => {
  //     if (error?.response?.data?.operation === 'validate_product') {
  //       setError('Este producto no está habilitado en este momento');
  //     } else {
  //       setError('Ocurrió un error al intentar adquirir el producto');
  //     }
  //   });
  // };
  return (
    <>
      <Container>
        <Title>¡Empecemos a potenciar tu negocio!</Title>
        {error && (
          <InlineAlert
            message={error}
            onClose={() => setError(null)}
            style={{ marginTop: '8px', marginBottom: '12px' }}
          />
        )}
        {!user.account.enterprise || someEnabledProductIsPaid ? (
          <>
            <SubTitle style={{ textAlign: 'center' }}>
              Estos son tus productos contratados y los que podrías adquirir.
            </SubTitle>
            <Carousel>
              {productsWithEnabled.map(({ product, enabled }) => {
                if (!enabled && product.acquire_behaviour === 'hidden') return null;

                if (user.account.enterprise && !enabled) return null;

                if (!product.countries.includes(country as Country)) return null;

                return (
                  <Product
                    description={product.description}
                    enabled={enabled}
                    href={product.uri}
                    key={product.name}
                    name={product.display_name}
                  />
                );
              })}
            </Carousel>
          </>
        ) : (
          <EnterpriseMessage />
        )}
      </Container>
    </>
  );
};

export default Platform;
