import { Grid, InputText } from '@increasecard/typed-components';

export default function CreditCardCreateForm(
  props: Omit<typeof InputText.arguments, 'className' | 'label' | 'name'>,
) {
  return (
    <div>
      <Grid rowGap={2}>
        <InputText
          className="grid-span-desktop-6"
          id="metadata.cardholder_name"
          label="Nombre del titular"
          name="metadata.cardholder_name"
          {...props}
        />
        <InputText
          className="grid-span-desktop-6"
          id="metadata.cardholder_document"
          label="DNI del titular"
          name="metadata.cardholder_document"
          {...props}
        />
        <InputText
          className="grid-span-desktop-6"
          id="metadata.number"
          label="Número de Tarjeta"
          name="metadata.number"
          {...props}
        />
        <InputText
          className="grid-span-desktop-3"
          id="metadata.expiration_month"
          label="Mes"
          maxLength="2"
          name="metadata.expiration_month"
          {...props}
        />
        <InputText
          className="grid-span-desktop-3"
          id="metadata.expiration_year"
          label="Año"
          maxLength="2"
          name="metadata.expiration_year"
          {...props}
        />
      </Grid>
    </div>
  );
}
