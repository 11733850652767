import styled from 'styled-components';

type GridProps = {
  rows?: string;
  rowGap?: string;
  columns?: string;
  columnWidth?: string;
  columnGap?: string;
};

const Grid = styled.div<GridProps>`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-rows: repeat(${({ rows }) => rows}, auto);
    grid-row-gap: ${({ rowGap }) => rowGap};
    grid-template-columns: repeat(${({ columns }) => columns}, ${({ columnWidth }) => columnWidth});
    grid-column-gap: ${({ columnGap }) => columnGap};
  }
`;

Grid.defaultProps = {
  rows: '1',
  rowGap: '14px',
  columnGap: '14px',
  columns: '9',
  columnWidth: '68px',
};
Grid.displayName = 'Grid';

export default Grid;
