import { useState, useEffect, useCallback } from 'react';
import { getPayments } from '../services/BillingService';

export default function usePayments() {
  const [payments, setPayments] = useState([]);

  const fetchPayments = useCallback(async () => {
    return getPayments().then(setPayments);
  }, []);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  return [payments, fetchPayments];
}
