import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FloatingNotification } from '@increasecard/typed-components';
import { withRouter } from 'react-router';

import { useNps } from '../contexts/useNps';
import { sendNpsResponse } from '../services/NpsService';

const propTypes = { className: PropTypes.string };

const defaultProps = {};

function NpsSurveyAlert({ className, history }) {
  const { surveys, removeSurvey } = useNps();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (surveys === null) return;
    setTimeout(() => setVisible(true), 1500);
  }, [surveys]);

  if (surveys === null) return null;
  if (!visible) return null;
  return (
    <div className={className}>
      {surveys.map((survey) => (
        <FloatingNotification
          actionText="Completar"
          bodyText="Tu opinión nos importa. Ayudanos respondiendo esta breve encuesta."
          cancelText="No, gracias"
          key={survey.id}
          onAction={() => {
            history.push(`/npsSurvey/${survey.product.name}`);
            removeSurvey(survey.id);
          }}
          onCancel={async () => {
            try {
              await sendNpsResponse(survey.id, { status: 'skipped' });
              removeSurvey(survey.id);
            } catch {
              removeSurvey(survey.id);
            }
          }}
        />
      ))}
    </div>
  );
}

NpsSurveyAlert.propTypes = propTypes;
NpsSurveyAlert.defaultProps = defaultProps;

export default withRouter(styled(NpsSurveyAlert)`
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 16px;
`);
