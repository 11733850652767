import { useState } from 'react';

interface ModalControls<Data> {
  isOpen: boolean;
  data: Data | null;
  open: (data?: Data) => void;
  close: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useModal<Data = any>(): ModalControls<Data> {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<Data | null>(null);

  const open = (data?: Data) => {
    setIsOpen(true);
    setData(data || null);
  };

  const close = () => {
    setIsOpen(false);
    setData(null);
  };

  return { isOpen, data, open, close };
}
