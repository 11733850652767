import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ContentWrapper } from '../components/Containers';
import BillingSettingsForm from '../components/BillingSettingsForm';
import { useBillingSettings } from '../hooks/useBillingSettings';
import InvoiceDetailTable from '../components/InvoiceDetailTable';
import styled from 'styled-components';
import useAvailablePaymentMethodTypes from '../hooks/useAvailablePaymentMethodTypes';
import usePaymentRequired from '../hooks/usePaymentRequired';
import LoadingIndicator from '../components/LoadingIndicator';
import { useCountry, useProducts, useUser } from '@increasecard/increase-core';
import { useTranslation, Trans } from 'react-i18next';
import usePaymentMethods from '../hooks/usePaymentMethods';
import PaymentMethodCreateForm from '../components/PaymentMethods/PaymentMethodCreateForm';
import { createPaymentMethod } from '../services/PaymentMethodsService';
import Grid from '../components/Grid';
import usePriceToPay from '../hooks/usePriceToPay';
import TrialEndNoBilling from './TrialEndNoBilling';
import { ApiErrorMessage } from '../components/ApiErrorMessage';

const propTypes = {};
const defaultProps = {};
function TrialEnd({ className, history, match }) {
  const { t } = useTranslation('trialEnd');
  const country = useCountry();
  const user = useUser();
  const { productCodename } = match.params;
  const products = useProducts();
  const [apiError, setApiError] = useState();
  const [billingSettings, setBillingSettings] = useBillingSettings();
  const [paymentMethods, fetchPaymentMethods] = usePaymentMethods();
  const availablePaymentMethods = useAvailablePaymentMethodTypes();
  const quantity = user.productQuantity(productCodename, country);
  const priceToPay = usePriceToPay({ productName: productCodename, country, units: quantity });
  const paymentRequired = usePaymentRequired();
  const product = products.find((product) => product.name === productCodename);

  if (user === null || products === null) return <LoadingIndicator />;

  if (!paymentRequired) {
    return (
      <TrialEndNoBilling
        className={className}
        history={history}
        match={match}
        priceToPay={priceToPay}
      />
    );
  }

  if (
    [availablePaymentMethods, billingSettings, paymentMethods, priceToPay].some((v) => v === null)
  ) {
    return <LoadingIndicator />;
  }

  const companySet = Object.values(billingSettings).length !== 0;
  const paymentMethodSet =
    paymentMethods.filter((paymentMethod) => paymentMethod.status === 'approved').length > 0;

  if (companySet && paymentMethodSet) history.push('/trialEnd/success');

  const handlePaymentMethodSubmit = async (paymentMethod) => {
    setApiError(null);
    try {
      await createPaymentMethod(paymentMethod);
      await fetchPaymentMethods();
    } catch (error) {
      setApiError(error);
    }
  };
  const handleBillingSettingsSubmit = async (values, overwriteTaxId) => {
    setApiError(null);
    try {
      await setBillingSettings(values, overwriteTaxId);
    } catch (error) {
      setApiError(error);
    }
  };

  return (
    <ContentWrapper className={className}>
      <h1>{t('important')}</h1>
      <p style={{ margin: '0' }}>
        <strong>{t('trialFinished')}</strong>
      </p>
      <p style={{ margin: '0 0 15px 0' }}>
        <Trans i18nKey="trialEnd:explain">
          <strong>{{ productName: product.display_name }}</strong>
        </Trans>
      </p>
      <InvoiceDetailTable
        currency={priceToPay.currency}
        quantity={priceToPay.quantity}
        totalPrice={priceToPay.total}
        unitPrice={priceToPay.unit_price}
      />
      <hr />
      <ApiErrorMessage error={apiError} marginTop={8} />
      <Grid columns="1" columnWidth="auto" rowGap="30px" rows="2">
        {!companySet && (
          <div>
            <h2>{t('businessName')}</h2>
            <BillingSettingsForm
              cancelText={t('noThanks')}
              country={user.defaultCountry}
              initialValues={billingSettings}
              onCancel={() => history.push(`/trialEnd/cancel/${productCodename}`)}
              onSubmit={handleBillingSettingsSubmit}
              submitText={t('saveAndContinue')}
            />
          </div>
        )}
        {companySet && !paymentMethodSet && (
          <div>
            <h2>{t('paymentMethod')}</h2>
            <PaymentMethodCreateForm
              availablePaymentMethods={availablePaymentMethods}
              cancelText={t('noThanks')}
              onCancel={() => history.push(`/trialEnd/cancel/${productCodename}`)}
              onSubmit={handlePaymentMethodSubmit}
              submitText={t('finishPurchase')}
            />
          </div>
        )}
      </Grid>
    </ContentWrapper>
  );
}

TrialEnd.propTypes = propTypes;
TrialEnd.defaultProps = defaultProps;

export default withRouter(styled(TrialEnd)`
  flex-direction: column;
  padding: 26px 96px;

  h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 8px;
  }
`);
