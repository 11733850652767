import platformAPI from './platformAPI';

export function getNpsSurveys() {
  return platformAPI.get('/v1/survey_responses').then((response) => response.data);
}

export function sendNpsResponse(id: string, surveyResponse: object) {
  return platformAPI.put(`/v1/survey_responses/${id}`, {
    survey_response: { status: 'finished', ...surveyResponse },
  });
}
