import { Button, Link } from '@increasecard/typed-components';
import styled from 'styled-components';

export const PrimaryButton = styled(Button)`
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colorsNew.increase.regular};
  width: 100%;
  :not(:disabled):not(:active):hover {
    box-shadow:
      1px 3px 4px rgba(1, 167, 123, 0.27),
      1px 2px 2px rgba(1, 167, 123, 0.36);
  }
  :not(:disabled):active {
    background-color: ${({ theme }) => theme.colorsNew.increase.dark};
  }
`;

export const InvisibleButton = styled(Button)`
  background: transparent;
  color: ${({ theme }) => theme.colorsNew.increase.regular};
  :not(:disabled):hover {
    background: rgba(1, 167, 123, 0.07);
  }
  :not(:disabled):active {
    color: ${({ theme }) => theme.colorsNew.increase.dark};
  }
`;

export const LinkButton = styled(Link)`
  color: ${({ theme }) => theme.colorsNew.increase.regular};
  :not(:disabled):hover {
    color: ${({ theme }) => theme.colorsNew.increase.dark};
  }
  :not(:disabled):active {
    color: ${({ theme }) => theme.colorsNew.increase.dark};
  }
`;
