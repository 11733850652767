import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@increasecard/typed-components';
import styled from 'styled-components';
import { ApiErrorMessage } from '../components/ApiErrorMessage';
import ButtonGroup from '../components/ButtonGroup';
import { cancelSubscription } from '../services/SubscriptionService';
import Modal from '../components/Modal';
import TrialEnd from './TrialEnd';

const propTypes = {};

const defaultProps = {};

function TrialEndCancelSurvey({ className, history, match }) {
  const { productCodename } = match.params;
  const [reason, setReason] = useState();
  const [apiError, setApiError] = useState(null);

  const handleTrialEndSubmit = async () => {
    setApiError(null);
    try {
      await cancelSubscription(productCodename, reason);
    } catch (error) {
      setApiError(error);
    }
  };

  return (
    <>
      <TrialEnd />
      <Modal className={className}>
        <ApiErrorMessage error={apiError} marginTop={8} />
        <header>
          <img alt="Logo de Increase" src="/IncreaseIcon.svg" />
          <h2 style={{ fontSize: '20px' }}>¿Nos contarías un poco más?</h2>
        </header>
        <form onSubmit={handleTrialEndSubmit}>
          <p style={{ marginTop: '0' }}>
            <strong>
              Lamentamos que no quieras seguir controlando tus ventas con tarjeta con nosotros.
            </strong>
          </p>
          <p style={{ marginTop: '0' }}>¿Por cuál de estos motivos tomaste la decisión?</p>
          <label>
            <input
              checked={reason === 'expensive'}
              name="reason"
              onChange={() => setReason('expensive')}
              type="radio"
              value="expensive"
            />{' '}
            Me parece caro
          </label>
          <label>
            <input
              checked={reason === 'productProblems'}
              name="reason"
              onChange={() => setReason('productProblems')}
              type="radio"
              value="productProblems"
            />{' '}
            El producto tiene problemas
          </label>
          <label>
            <input
              checked={reason === 'trialTooShort'}
              name="reason"
              onChange={() => setReason('trialTooShort')}
              type="radio"
              value="trialTooShort"
            />{' '}
            El tiempo de prueba no me alcanzó
          </label>
          <label>
            <input
              checked={reason === 'expectationFailure'}
              name="reason"
              onChange={() => setReason('expectationFailure')}
              type="radio"
              value="expectationFailure"
            />{' '}
            No me gustó / No es lo que esperaba
          </label>
          <ButtonGroup style={{ marginTop: '24px' }}>
            <Button disabled={reason === undefined} type="submit">
              Enviar
            </Button>
            <Button buttonType="invisible" onClick={() => history.goBack()}>
              Regresar
            </Button>
          </ButtonGroup>
        </form>
      </Modal>
    </>
  );
}

TrialEndCancelSurvey.propTypes = propTypes;
TrialEndCancelSurvey.defaultProps = defaultProps;

export default withRouter(styled(TrialEndCancelSurvey)`
  header {
    background-color: #131a2f;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-content: center;
  }

  label:not(:first-child) {
    margin-top: 8px;
  }
`);
