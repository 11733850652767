import * as Yup from 'yup';
import CbuMetadataSchema from './CbuMetadataSchema';
import CreditCardMetadataSchema from './CreditCardMetadataSchema';

export default Yup.object({
  type: Yup.string().oneOf([
    'PaymentMethods::Cbu',
    'PaymentMethods::PlacetoPay',
    'PaymentMethods::CreditCard',
  ]),
  metadata: Yup.object()
    .when('type', {
      is: (value) => value === 'PaymentMethods::Cbu',
      then: CbuMetadataSchema,
    })
    .when('type', {
      is: (value) => value === 'PaymentMethods::CreditCard',
      then: CreditCardMetadataSchema,
    }),
});
