import client from './platformAPI';

export function getPaymentMethods(country) {
  return client
    .get('/v1/payment_methods', { headers: { tenant: country } })
    .then((response) => response.data);
}

export function getAvailablePaymentMethodTypes(country) {
  return client
    .get('/v1/payment_methods/types', { headers: { Tenant: country } })
    .then((response) => response.data);
}

export function updatePaymentMethod(paymentMethod) {
  return client.put(`/v1/payment_methods/${paymentMethod.id}`, { payment_method: paymentMethod });
}

export function createPaymentMethod(paymentMethod) {
  return client.post(`/v1/payment_methods`, { payment_method: paymentMethod });
}
