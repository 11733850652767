import React, { useState, useEffect } from 'react';
import { getNpsSurveys } from '../services/NpsService';
import { NpsContext, NpsState } from './context';

export function NpsProvider({ children }: { children: React.ReactNode }) {
  const [npsState, setNpsState] = useState<NpsState>({ surveys: [] });
  const removeSurvey = (id: string) => {
    setNpsState((prevState) => {
      return {
        ...prevState,
        surveys: prevState.surveys && prevState.surveys.filter((survey) => survey.id !== id),
      };
    });
  };

  useEffect(() => {
    getNpsSurveys()
      .then((surveys) => {
        setNpsState({ surveys });
      })
      .catch(() => setNpsState({ surveys: [] }));
  }, []);
  if (npsState.surveys === null) return null;

  return (
    <NpsContext.Provider value={{ ...npsState, removeSurvey }}>{children}</NpsContext.Provider>
  );
}
