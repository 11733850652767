import config from './config';

export const routes = {
  platform: {
    home: '/',
    oAuthHandler: '/auth',
    settings: {
      index: '/settings',
      profile: '/settings/profile',
      products: '/settings/products',
      user: '/settings/user',
    },
  },
  products: {
    card: config.cardFrontUrl,
    conciliation: config.conciliationFrontUrl,
  },
  maintenance: '/maintenance',
  signOut: `${config.authUrl}/logout`,
  invoices: {
    index: '/invoices',
    settings: {
      billing: '/invoices/settings/billing',
      paymentMethods: '/invoices/settings/paymentMethods',
    },
  },
  advanced: {
    index: '/advanced/settings/users',
  },
  payments: '/payments',
  support: '/support',
};
