import React, { useState } from 'react';
import { MainContent, FormContainer, ContentWrapper } from '../components/Containers';
import SectionTitle from '../components/SectionTitle';
import SettingsSidebar from '../components/SettingsSidebar';
import SettingsMeForm from '../components/SettingsMeForm';
import { useMeSettings } from '../hooks/useMeSettings';
import LoadingIndicator from '../components/LoadingIndicator';
import { useTranslation, Trans } from 'react-i18next';
import InlineAlert from '../components/InlineAlert';
import { ApiErrorMessage } from '../components/ApiErrorMessage';

function SettingsMe() {
  const [apiError, setApiError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { t } = useTranslation('settingsMe');
  const { meSettings, updateMeSettings } = useMeSettings();

  const onSubmit = async (values) => {
    try {
      setApiError(null);
      setSuccessMessage(null);

      await updateMeSettings(meSettings.id, values);
      setSuccessMessage(t('savedSuccessfully'));
      setApiError(null);
    } catch (error) {
      setApiError(error);
      setSuccessMessage(null);
    }
  };

  function onSuccessMessageClose() {
    setSuccessMessage(null);
  }

  const loadingUser = meSettings === null;

  return (
    <ContentWrapper>
      <SettingsSidebar settings="user" />
      <MainContent>
        <SectionTitle>{t('title')}</SectionTitle>
        <p>
          <Trans i18nKey="settingsMe:userSettingsDescription" t={t} />
        </p>
        <FormContainer>
          {loadingUser ? (
            <LoadingIndicator />
          ) : (
            <SettingsMeForm
              initialValues={meSettings}
              onSubmit={onSubmit}
              submitText={t('submitForm')}
            />
          )}
        </FormContainer>
        {successMessage && (
          <InlineAlert
            message={successMessage}
            onClose={onSuccessMessageClose}
            style={{ marginTop: '40px' }}
            variant="info"
          />
        )}
        <ApiErrorMessage error={apiError} marginTop={40} />
      </MainContent>
    </ContentWrapper>
  );
}

export default SettingsMe;
