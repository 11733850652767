import axios, { AxiosError, AxiosInstance } from 'axios';
import { reportError } from '../components/ErrorTracker';
import config from '../config';
import { ApiError } from '../types/ApiError';

const client = createClient(config.platformApiUrl, true);
export default client;

function createClient(baseURL: string, withCredentials: boolean) {
  const client = axios.create({
    baseURL,
    withCredentials,
  });
  addInterceptors(client);
  return client;
}

function addInterceptors(client: AxiosInstance) {
  // Intercept response
  client.interceptors.response.use(
    (response) => response,
    (error) => errorInterceptor(error),
  );
}

function errorInterceptor(error: AxiosError): Promise<ApiError> {
  const { response, message } = error;
  // check if response is present
  if (response) {
    const { status, data } = response;
    if (status === 401) {
      // Token expired, go to login
      redirectToAuthLogin();
      return Promise.reject();
    }
    /*
    Default error response should be:
    {
      errors: [{
        code: INTERNAL_ERROR_NAME, (required, e.g USER_CAN_NOT_EDIT_ANOTHER_USER)
        message: "Nice description" (required)
        metadata: null || { ... (optional keys) }
      }]
    }
    */
    const { errors } = data;
    if (errors && Array.isArray(errors) && errors.length > 0) {
      // Parse first error
      return Promise.reject(ApiError.fromAPI(errors[0]));
    }
  }
  // no response in the error
  if (message && message.toLowerCase() === 'network error') {
    // probably a 401 -> https://github.com/axios/axios/issues/383
    redirectToAuthLogin();
  }
  // unexpected error
  reportError('Unexpected API error: ', error);
  return Promise.reject(ApiError.fromAPI(error));
}

function redirectToAuthLogin() {
  window.location.assign(`${config.authUrl}/login?redirect=${window.location.href}`);
}
