import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NPSSurvey from '../components/NPSSurvey';
import Icon from '../components/Icon';
import { Done } from '../icons/icons';
import { sendNpsResponse, getNpsSurveys } from '../services/NpsService';
import LoadingIndicator from '../components/LoadingIndicator';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {};

const SuccessText = styled.h1`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.4px;
  font-weight: 500;
  display: inline-flex;
  align-items: flex-end;
  flex-direction: row;
`;

function useNpsSurveys() {
  const [surveys, setSurveys] = useState(null);
  useEffect(() => {
    getNpsSurveys().then(setSurveys);
  }, []);
  return surveys;
}

function NPSSurveyPage({ className, match }) {
  const [submitted, setSubmitted] = useState(false);
  const surveys = useNpsSurveys();
  const { productName } = match.params;
  if (surveys === null) return <LoadingIndicator />;
  const currentSurvey = surveys.filter(
    (survey) => survey.status === 'pending' && survey.product.name === productName
  )[0];
  if (!currentSurvey) {
    return (
      <div className={className} style={{ color: '#00d246' }}>
        <SuccessText>Ya has respondido esta encuesta. Gracias por participar.</SuccessText>
      </div>
    );
  }
  if (submitted) {
    return (
      <div className={className} style={{ color: '#00d246' }}>
        <SuccessText>
          <Icon src={Done} style={{ marginRight: '4px' }} />
          ¡Enviado! Gracias por darnos tu opinión.
        </SuccessText>
      </div>
    );
  }
  return (
    <div className={className}>
      <NPSSurvey
        onSubmit={async (surveyResponse) => {
          await sendNpsResponse(currentSurvey.id, surveyResponse);
          setSubmitted(true);
        }}
        productName={productName}
      />
    </div>
  );
}

NPSSurveyPage.propTypes = propTypes;
NPSSurveyPage.defaultProps = defaultProps;

export default styled(NPSSurveyPage)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: calc(100vh - 140px);
`;
