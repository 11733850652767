import { useState, useEffect } from 'react';
import {
  Currency,
  Table,
  Td,
  TableRow,
  Th,
  THead,
  TableWrapper,
  Link,
} from '@increasecard/typed-components';
import { DownloadIcon } from '@increasecard/icons';
import styled from 'styled-components';
import { MainContent, ContentWrapper } from '../components/Containers';
import SectionTitle from '../components/SectionTitle';
import { getInvoices } from '../services/BillingService';
import Invoice from '../models/Invoice';
import LoadingIndicator from '../components/LoadingIndicator';
import { User, UserSubscription, useUser } from '@increasecard/increase-core';
import SettingsSidebar from '../components/SettingsSidebar';

const PayUsersCaption = styled.p`
  margin: 30px auto;
`;

const DownloadButton = styled.a.attrs({ children: <DownloadIcon height="24" width="24" /> })`
  color: ${({ theme }) => theme.colors.brandBlack.regular};
`;

const resolveUsablePaySubscription = (user: User | null) => {
  if (!user) return false;
  const paySubscription = user.subscriptions.find(
    (sub: UserSubscription) => sub.product.name === 'pay',
  );
  if (!paySubscription) return false;
  return paySubscription.state === 'awaiting_setup' || paySubscription.state === 'usable';
};

const Invoices = () => {
  const [invoices, setInvoices] = useState<Invoice[] | null>(null);
  const user = useUser();
  const hasUsablePaySubscription = resolveUsablePaySubscription(user);

  useEffect(() => {
    getInvoices().then(setInvoices);
  }, []);

  const loadingInvoices = invoices === null || user === null;

  return (
    <ContentWrapper>
      <SettingsSidebar settings="invoices" />
      <MainContent>
        <SectionTitle>Tus Facturas</SectionTitle>
        {hasUsablePaySubscription && (
          <PayUsersCaption>
            Las facturas emitidas por el servicio de Pay llegarán al correo electrónico registrado
            al momento de adquirir la Suscripción. Si necesitas modificar este correo electrónico o
            tienes alguna consulta comunícate con nuestro equipo de
            <Link
              forwardedAs="a"
              href="https://soporte.increasecard.com/hc/es/requests/new?ticket_form_id=360003356153"
              style={{ margin: '0 5px' }}
              type="button"
            >
              Atención al Cliente
            </Link>
          </PayUsersCaption>
        )}
        {loadingInvoices ? (
          <LoadingIndicator />
        ) : invoices?.length === 0 ? (
          <p>No hay facturas disponibles</p>
        ) : (
          <TableWrapper>
            <Table zebra>
              <THead>
                <TableRow>
                  <Th>Razón Social</Th>
                  <Th>Nº de Factura</Th>
                  <Th>Fecha de emisión</Th>
                  <Th>Vencimiento</Th>
                  <Th rightAligned>Importe</Th>
                  <Th rightAligned>Descargar</Th>
                </TableRow>
              </THead>
              <tbody>
                {invoices?.map(
                  ({
                    number,
                    companyLegalName,
                    displayEmissionDate,
                    displayExpirationDate,
                    currency,
                    amount,
                    documentLink,
                  }) => (
                    <TableRow double key={number}>
                      <Td>{companyLegalName}</Td>
                      <Td>{number}</Td>
                      <Td>{displayEmissionDate}</Td>
                      <Td>{displayExpirationDate}</Td>
                      <Td rightAligned>
                        <Currency currency={currency} language="es-AR" value={amount} />
                      </Td>
                      <Td rightAligned>
                        <DownloadButton
                          href={documentLink}
                          rel="noopener noreferrer"
                          target="_blank"
                        />
                      </Td>
                    </TableRow>
                  ),
                )}
              </tbody>
            </Table>
          </TableWrapper>
        )}
      </MainContent>
    </ContentWrapper>
  );
};

export default Invoices;
