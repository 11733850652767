import styled from 'styled-components';
import { H1 } from '@increasecard/typed-components';

const SectionTitle = styled(H1)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colorsNew.increase.dark70};
  width: fit-content;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 31px;
  }
`;

export default SectionTitle;
