import { useState, useEffect, useCallback } from 'react';
import { getPaymentMethods } from '../services/PaymentMethodsService';
import { useCountry } from '@increasecard/increase-core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PaymentMethod = any;

function filterCreditCardType(paymentMethods: PaymentMethod[]) {
  return paymentMethods.filter(
    (paymentMethod: PaymentMethod) => !paymentMethod.type.toLowerCase().includes('creditcard'),
  );
}

export default function usePaymentMethods(): [PaymentMethod[] | null, () => Promise<void>] {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>(null);
  const country = useCountry();
  const fetchPaymentMethods = useCallback(async () => {
    // (dbelay) filter credit card option in case the API sends it [PL-184]
    return getPaymentMethods(country).then(filterCreditCardType).then(setPaymentMethods);
  }, [country]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [country, fetchPaymentMethods]);

  return [paymentMethods, fetchPaymentMethods];
}
