import '@increasecard/typed-components/index.css';

import React, { Suspense } from 'react';
import { Routes } from './containers/Routes';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from '@increasecard/typed-components';
import I18nLoader from './components/I18nLoader';
import { BaseProvider, Environment, useEventTracker } from '@increasecard/increase-core';
import LoadingIndicator from './components/LoadingIndicator';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { config } from './config';
import { ErrorTrackerLoader } from './components/ErrorTracker';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const trackEvents: boolean = import.meta.env.REACT_APP_EVENTS_TRACK === 'true';
const trackErrors: boolean = import.meta.env.VITE_ENV === 'production';
const environment = import.meta.env.VITE_ENV as Environment | undefined;
const mixpanelToken: string = config.mixpanelToken;
const rollbarToken: string = config.rollbarToken;

function RouteChangeTracker() {
  const location = useLocation();
  const eventTracker = useEventTracker();
  React.useEffect(() => {
    eventTracker.track('Page View', { path: location.pathname });
  }, [location, eventTracker]);

  return null;
}

function App() {
  return (
    <ThemeProvider productName="count">
      <BaseProvider
        settings={{
          environment: environment || 'development',
          productName: 'platform',
          eventTracking: { enabled: trackEvents, mixpanelToken },
          errorTracking: { enabled: trackErrors, rollbarToken },
        }}
      >
        <BrowserRouter>
          <>
            <ErrorTrackerLoader />
            <RouteChangeTracker />
            <GlobalStyle />
            <Suspense
              fallback={
                <div style={{ height: '100vh', width: '100vw' }}>
                  <LoadingIndicator />
                </div>
              }
            >
              <I18nLoader>
                <Routes />
              </I18nLoader>
            </Suspense>
          </>
        </BrowserRouter>
      </BaseProvider>
    </ThemeProvider>
  );
}

export default App;
