import { useState, useEffect } from 'react';
import { getPrice } from '../services/ProductService';

export default function usePriceToPay({ productName, country, units }) {
  const [priceToPay, setPriceToPay] = useState({ quantity: 0, total: 0, unit_price: 0 });

  useEffect(() => {
    if (!units) return;
    getPrice({ productName, country, units }).then(setPriceToPay);
  }, [productName, country, units]);

  return priceToPay;
}
