import { Grid, InputText } from '@increasecard/typed-components';

export interface CreditCardShowProps {
  metadata: {
    number: string;
    cardholder_name: string;
    cardholder_document: string;
    expiration_month: string;
    expiration_year: string;
  };
}

export default function CreditCardShow({ metadata }: CreditCardShowProps) {
  return (
    <div>
      <Grid rowGap={3}>
        <InputText
          className="grid-span-desktop-6"
          label="Nombre del titular"
          readOnly
          value={metadata.cardholder_name}
        />
        <InputText
          className="grid-span-desktop-6"
          label="DNI del titular"
          readOnly
          value={metadata.cardholder_document}
        />
        <InputText
          className="grid-span-desktop-6"
          label="Número de Tarjeta"
          readOnly
          row="2"
          value={metadata.number}
        />
        <InputText
          className="grid-span-desktop-3"
          label="Mes"
          maxLength="2"
          readOnly
          row="2"
          value={metadata.expiration_month}
        />
        <InputText
          className="grid-span-desktop-3"
          label="Año"
          maxLength="2"
          readOnly
          row="2"
          value={metadata.expiration_year}
        />
      </Grid>
    </div>
  );
}
