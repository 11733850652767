const formatter = new Intl.DateTimeFormat(undefined, {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

export default function FriendlyDateTime({ date }: { date: Date }) {
  return formatter.format(date);
}
