import Grid from '../Grid';
import { InputText } from '@increasecard/typed-components';

export interface PlacetoPayShowProps {
  metadata: {
    cardholder_name: string;
    last_digits: string;
    valid_until: string;
  };
}

export default function PlacetoPayShow({ metadata }: PlacetoPayShowProps) {
  return (
    <div>
      <p>Tarjeta de crédito suscripta por PlacetoPay</p>
      <Grid rowGap="30px" rows="2">
        <InputText
          className="grid-span-desktop-4"
          label="Nombre del titular"
          readOnly
          value={metadata.cardholder_name}
        />
        <InputText
          className="grid-span-desktop-4"
          label="Últimos 4 números"
          readOnly
          value={metadata.last_digits}
        />
        <InputText
          className="grid-span-desktop-4"
          label="Válida hasta"
          maxLength="2"
          readOnly
          value={metadata.valid_until}
        />
      </Grid>
    </div>
  );
}
