import { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import VoiceIcon from '../icons/ic_record_voice_over.svg';
import ContactIcon from '../icons/ic_headset_mic.svg';

import { useTranslation } from 'react-i18next';
import { HelpIcon } from '@increasecard/icons';

export const ResourcesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

type ResourceProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  to: string;
  text: string;
  className?: string;
  label: string;
  icon: JSX.Element;
};
const Resource = ({ to, text, className, label, icon, ...rest }: ResourceProps) => {
  if (to === '') {
    return null;
  }
  return (
    <div className={className}>
      {icon}
      <div className="right">
        <a href={to} {...rest}>
          {label}
        </a>
        <p>{text}</p>
      </div>
    </div>
  );
};

export const ResourceStyled = styled(Resource)`
  display: flex;
  flex: 1 0 50%;
  text-decoration: none;
  margin-bottom: 1.25rem;

  .iconImg {
    width: 1rem;
    object-fit: scale-down;
    margin-top: 0.25rem;
  }

  .right {
    margin-left: 1rem;
    a {
      ${({ theme }) => theme.typography.cell}
      /* TODO(santi698): Check this color with design team */
      color: #3b86ff;
      margin: 0;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.03rem;
    }
    p {
      ${({ theme }) => theme.typography.cell}
      margin: 0;
    }
  }
`;

export default function UsefulResources() {
  const { t } = useTranslation('resources');
  return (
    <ResourcesWrapper>
      <ResourceStyled
        icon={<HelpIcon color="#a9adb7" height="20" width="20" />}
        label={t('supportCenter')}
        target="_blank"
        text={t('supportCenterDesc')}
        to={t('supportCenterLink')}
      />
      <ResourceStyled
        icon={<ContactIcon />}
        label={t('contact')}
        target="_blank"
        text={t('contactDesc')}
        to={t('contactLink')}
      />
      <ResourceStyled
        icon={<VoiceIcon />}
        label={t('yourOpinion')}
        target="_blank"
        text={t('yourOpinionDesc')}
        to={t('yourOpinionLink')}
      />
    </ResourcesWrapper>
  );
}
