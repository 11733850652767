import styled from 'styled-components';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import { Link } from '@increasecard/typed-components';
import config from '../config';
import { useCountry } from '@increasecard/increase-core';

const LinksWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 232px;
    background-color: ${(props) => props.theme.colorsNew.gray.white};
    box-shadow: 0px 3px 9px rgba(24, 32, 38, 0.18);
  }
`;

const LinksContent = styled.div`
  margin: 20px 0 0 26px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 50px 0 0 40px;
  }
`;

const LinkStyled = styled(Link)`
  margin-bottom: 26px;
  color: #1c2644;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  width: fit-content;

  &.active {
    color: ${({ theme }) => theme.colorsNew.increase.dark70};
  }
  &.active::before {
    content: '';
    position: absolute;
    border-left: 3px solid ${({ theme }) => theme.colorsNew.increase.dark70};
    left: -12px;
    height: 26px;
  }
`;

type SettingsProps = {
  settings: string;
};

const SettingsSidebar = ({ settings }: SettingsProps) => {
  const country = useCountry();
  return (
    <LinksWrapper>
      <LinksContent>
        {settings === 'user' && (
          <>
            <LinkStyled as={ReactRouterLink} to="/settings/user">
              Perfil
            </LinkStyled>
            <LinkStyled href={`${config.authUrl}/password/change`} target="_blank">
              Cambiar contraseña
            </LinkStyled>
          </>
        )}

        {settings === 'invoices' && country !== 'argentina' ? (
          <>
            <LinkStyled as={ReactRouterLink} exact to="/invoices">
              Tus facturas
            </LinkStyled>
            <LinkStyled as={ReactRouterLink} to="/invoices/settings/billing">
              Datos de facturación
            </LinkStyled>
          </>
        ) : (
          settings === 'invoices' && (
            <>
              <LinkStyled as={ReactRouterLink} exact to="/invoices">
                Tus facturas
              </LinkStyled>
              <LinkStyled as={ReactRouterLink} to="/invoices/settings/billing">
                Datos de facturación
              </LinkStyled>
              <LinkStyled as={ReactRouterLink} to="/invoices/settings/paymentMethods">
                Medios de pago
              </LinkStyled>
            </>
          )
        )}

        {settings === 'advanced' && (
          <>
            <LinkStyled as={ReactRouterLink} to="/advanced/settings/users">
              Usuarios
            </LinkStyled>
            <LinkStyled
              href="https://developers.increase.app/docs/accesos-por-rol-pay/"
              target="_blank"
            >
              Permisos por rol
            </LinkStyled>
          </>
        )}
      </LinksContent>
    </LinksWrapper>
  );
};

export default SettingsSidebar;
