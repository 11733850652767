import React, { useEffect, useState } from 'react';
import { differenceInMinutes } from 'date-fns';
import usePaymentMethods from '../hooks/usePaymentMethods';
import useToggle from '../hooks/useToggle';
import { useCountry } from '@increasecard/increase-core';

import Modal from './Modal';
import IconButton from './IconButton';
import Close from '../icons/Close';
import LoadingIndicator from './LoadingIndicator';

const RECENT_MINUTES_THRESHOLD = 5;
const POLL_INTERVAL_MS = 1000;

/* eslint-disable react-hooks/exhaustive-deps */
export default function PaymentMethodResultModal() {
  const [modalContent, setModalContent] = useState<JSX.Element | undefined>();
  const { value: showModal, toggle: toggleModal } = useToggle();
  const [paymentMethods, fetchPaymentmethods] = usePaymentMethods();
  const country = useCountry();

  useEffect(() => {
    if (paymentMethods === null) return;
    const lastPaymentMethod = paymentMethods.find(
      (paymentMethod) =>
        differenceInMinutes(new Date(), new Date(paymentMethod.created_at)) <
        RECENT_MINUTES_THRESHOLD,
    );
    if (!lastPaymentMethod || country !== 'ecuador') return;
    let message;
    let timeoutId: number | null = null;
    switch (lastPaymentMethod.status) {
      case 'approved':
        message = (
          <React.Fragment>
            <p>Tu suscripción a Placetopay fue aprobada.</p>
            <p>
              Un cobro de prueba de 1 USD fue realizado y anulado en este proceso para validar tu
              tarjeta.
            </p>
          </React.Fragment>
        );
        break;
      case 'rejected':
        message = (
          <React.Fragment>
            <p>Tu suscripción a Placetopay fue rechazada.</p>
            <p>Se intentó realizar un cobro por 1 USD para validar tu tarjeta sin éxito.</p>
          </React.Fragment>
        );
        break;
      case 'waiting':
      case 'pending':
        message = (
          <React.Fragment>
            <p>Tu suscripción a Placetopay está siendo validada.</p>
            <p>
              En este proceso se hará un cobro de prueba de 1 USD para validar tu tarjeta que luego
              será anulado.
            </p>
            <p>Éste proceso puede tardar entre 1 y 2 minutos.</p>
            <LoadingIndicator />
          </React.Fragment>
        );
        timeoutId = window.setTimeout(fetchPaymentmethods, POLL_INTERVAL_MS);
        break;
      default:
        break;
    }
    setModalContent(message);
    if (!showModal) toggleModal();
    if (timeoutId !== null) {
      return () => clearTimeout(timeoutId as number);
    }
  }, [paymentMethods, fetchPaymentmethods]);

  return (
    <React.Fragment>
      <Modal hidden={!showModal}>
        <div style={{ backgroundColor: 'white', padding: '15px', position: 'relative' }}>
          <IconButton onClick={toggleModal}>
            <Close />
          </IconButton>
          {modalContent}
        </div>
      </Modal>
    </React.Fragment>
  );
}
