import React, { Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Platform from './Index';
import Maintenance from './Maintenance';
import { routes } from '../routes';
import LoadingIndicator from '../components/LoadingIndicator';
import TrialEnd from './TrialEnd';
import TrialEndCancelSurvey from './TrialEndCancelSurvey';
import TrialEndSuccess from './TrialEndSuccess';
import NPSSurveyPage from './NpsSurveyPage';
import NpsSurveyAlert from './NpsSurveyAlert';
import { NpsProvider } from '../contexts/NpsContext';
import SettingsPaymentMethods from './SettingsPaymentMethods';
import { useAuth, useAppcues } from '@increasecard/increase-core';
import { Layout } from '../components/Layout';
import Invoices from './Invoices';
import Payments from './Payments';
import SettingsBilling from './SettingsBilling.jsx';
import SettingsUsers from './SettingsUsers';
import PaySettingsBilling from './PaySettingsBilling';
import PayCheckoutSuccess from './PayCheckoutSuccess';
import SettingsMe from './SettingsMe';
import SettingsUser from './SettingsUser';
import { Support } from './Support';

function withLayout(Component: React.ComponentType, layoutProps: typeof Layout.arguments = {}) {
  const ComponentWithLayout = () => (
    <Layout {...layoutProps}>
      <Component />
    </Layout>
  );
  ComponentWithLayout.displayName = `${Component.displayName}WithLayout`;
  return ComponentWithLayout;
}

export function Routes() {
  useAuth({ requireAuth: true });
  const location = useLocation();

  useAppcues('platform', location.pathname);

  return (
    <>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <Route component={withLayout(Platform)} exact path={routes.platform.home} />
          <Route component={withLayout(Maintenance)} exact path={routes.maintenance} />
          <Route component={withLayout(Invoices)} exact path={routes.invoices.index} />
          <Route component={withLayout(Payments)} exact path={routes.payments} />
          <Route component={withLayout(Support)} exact path={routes.support} />
          <Route
            component={withLayout(SettingsBilling)}
            exact
            path={routes.invoices.settings.billing}
          />
          <Route
            component={withLayout(SettingsPaymentMethods)}
            exact
            path={routes.invoices.settings.paymentMethods}
          />
          <Route component={withLayout(SettingsUsers)} exact path={routes.advanced.index} />
          <Route component={withLayout(SettingsUser)} exact path="/advanced/settings/users/:id" />
          <Route
            component={withLayout(SettingsMe)}
            path={[routes.platform.settings.user, routes.platform.settings.index]}
          />
          <Route
            component={withLayout(TrialEndSuccess, { contextNavEnabled: false })}
            exact
            path="/trialEnd/success"
          />
          <Route
            component={withLayout(TrialEnd, { contextNavEnabled: false })}
            exact
            path="/trialEnd/:productCodename"
          />
          <Route
            component={withLayout(TrialEndCancelSurvey, { contextNavEnabled: false })}
            exact
            path="/trialEnd/cancel/:productCodename"
          />
          <Route
            component={withLayout(NPSSurveyPage, { contextNavEnabled: false })}
            exact
            path="/npsSurvey/:productName"
          />
          <Route component={PaySettingsBilling} exact path="/pay/billing" />
          <Route component={PayCheckoutSuccess} exact path="/pay/success" />
        </Switch>
      </Suspense>
      <NpsProvider>
        <NpsSurveyAlert />
      </NpsProvider>
    </>
  );
}
