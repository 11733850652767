import client from './platformAPI';

export async function startPlacetoPaySubscription() {
  const response = await client.post(
    '/payments/placetopay',
    { product: 'card' },
    { headers: { Tenant: 'ecuador' } },
  );
  window.location.href = response.data.link;
}
