import styled from 'styled-components';

const IconButton = styled.button`
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  cursor: pointer;
  background: none;
`;

export default IconButton;
