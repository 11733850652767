import { SidebarLayout } from '../components/PaySettingsBilling/SidebarLayout';
import CenteredLayout from '../components/PaySettingsBilling/CenteredLayout';
import ResponsiveTitle from '../components/PaySettingsBilling/ResponsiveTitle';
import { Paragraph } from '@increasecard/typed-components';
import { PrimaryButton } from '../components/PaySettingsBilling/Buttons';
import { config } from '../config';

const PayCheckoutSuccess = () => {
  return (
    <SidebarLayout>
      <CenteredLayout>
        <ResponsiveTitle>¡Te damos la bienvenida!</ResponsiveTitle>
        <Paragraph style={{ margin: '20px 0' }}>
          Tu suscripción se ha realizado con éxito. Haz click en el siguiente botón y serás
          redireccionado al producto.
        </Paragraph>
        <PrimaryButton
          forwardedAs="a"
          href={`${config.payFrontUrl}`}
          style={{ width: 'unset' }}
          target="_self"
        >
          Pay
        </PrimaryButton>
      </CenteredLayout>
    </SidebarLayout>
  );
};

export default PayCheckoutSuccess;
