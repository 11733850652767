import { InputText, InputBaseProps } from '@increasecard/typed-components';

export type TaxIDProps = {
  country: string;
} & InputBaseProps;

export type InputPropsByCountry = {
  [key: string]: {
    [Property in keyof InputBaseProps]: number | string;
  };
};

const PropsByCountry: InputPropsByCountry = {
  argentina: {
    minLength: 11,
    maxLength: 11,
  },
  chile: {
    minLength: 9,
    maxLength: 9,
  },
  colombia: {
    minLength: 9,
    maxLength: 10,
  },
  dominicana: {
    minLength: 9,
    maxLength: 9,
  },
  ecuador: {
    minLength: 13,
    maxLength: 13,
  },
  peru: {
    minLength: 11,
    maxLength: 11,
  },
  uruguay: {
    minLength: 12,
    maxLength: 12,
  },
};

export function TaxIdInput(props: TaxIDProps) {
  return <InputText {...props} {...PropsByCountry[props.country]} />;
}
