import styled from 'styled-components';

const ButtonGroup = styled.div`
  > *:not(:first-child) {
    margin-left: 5px;
  }
`;

ButtonGroup.displayName = 'ButtonGroup';
export default ButtonGroup;
