import { ReactNode } from 'react';
import styled from 'styled-components';
import { IncreaseSidebar } from './IncreaseSidebar';

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  flex-direction: column;

  @media (min-width: 1100px) {
    display: flex;
    width: calc(70%);
    flex-direction: column;
    position: relative;
  }
`;

const Sidebar = styled.div`
  display: flex;
  @media (min-width: 1100px) {
    display: flex;
    min-height: 500px;
    > div {
      height: 100%;
      min-height: 500px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @media (min-width: 1100px) {
    flex-direction: row;
  }
`;

export const SidebarLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Container>
      <Sidebar>
        <IncreaseSidebar />
      </Sidebar>
      <Content>{children}</Content>
    </Container>
  );
};
