import React from 'react';
import styled from 'styled-components';
import { InvisibleButton } from './Buttons';
import { ErrorOutline, MarkUnread } from '../icons/icons';
import Close from '../icons/Close';

const VARIANT_SETTINGS = {
  alert: { color: '#e71321', icon: ErrorOutline },
  info: { color: '#01A77B', icon: MarkUnread },
};

type InlineAlertProps = {
  message: string;
  onClose: () => void;
  variant?: 'alert' | 'info';
} & React.HTMLAttributes<HTMLDivElement>;

export default function InlineAlert({
  message,
  onClose,
  variant = 'alert',
  ...rest
}: InlineAlertProps) {
  const settings = VARIANT_SETTINGS[variant];
  const Wrapper = styled.div`
    border: 1px solid ${settings.color};
    border-radius: 3px;
    padding: 5px 5px 5px 20px;
    color: ${settings.color};
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `;
  return (
    <Wrapper {...rest}>
      {message}
      <InvisibleButton buttonType="invisible" onClick={onClose}>
        <Close />
      </InvisibleButton>
    </Wrapper>
  );
}
