import platformAPI from './platformAPI';
import { Invitation } from '../models/Invitation';

export function getInvitations(): Promise<Invitation[]> {
  return platformAPI.get('/v1/invitations').then((response) => response.data.data as Invitation[]);
}

export function createInvitation({
  email,
  role,
}: {
  email: string;
  role: number;
}): Promise<Invitation> {
  return platformAPI
    .post('/v1/invitations', { email, role })
    .then((response) => response.data as Invitation);
}

export function resendInvitation(id: string): Promise<Invitation> {
  return platformAPI.post(`/v1/invitations/${id}/resend`).then((response) => response.data);
}

export function removeInvitation(id: string): Promise<Invitation> {
  return platformAPI.delete(`/v1/invitations/${id}`).then((response) => response.data);
}

export function verifyEmail(email: string) {
  const regex = /^([a-z0-9_.-/+]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
  return regex.test(email);
}
