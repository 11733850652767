import styled from 'styled-components';
import ProductDesc from './ProductDesc';
import { ThemeProvider, ThemeProviderProps, Button } from '@increasecard/typed-components';

type ButtonProps = {
  enabled?: boolean;
};

const ProductWrapper = styled.div<Pick<ButtonProps, 'enabled'>>`
  width: 320px;
  height: 204px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colorsNew.gray.grayLight};
  border: ${({ enabled, theme }) =>
    enabled
      ? `1px solid ${theme.colorsNew.product.regular}`
      : `1px solid ${theme.colorsNew.gray.grayBold}`};
  box-sizing: border-box;
  box-shadow:
    0px 3px 2px rgba(24, 32, 38, 0.07),
    0px 2px 2px rgba(24, 32, 38, 0.06),
    0px 1px 3px rgba(84, 138, 179, 0.09);
  border-radius: 9px;
  position: relative;
  overflow: hidden;
  padding: 24px 24px 12px 24px;
  margin: 0 8px;
  transition: all ease-in-out 0.3s;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 16px;
  }

  :not(:disabled):hover {
    box-shadow:
      0px 16px 24px rgba(24, 32, 38, 0.13),
      0px 6px 30px rgba(24, 32, 38, 0.11),
      0px 8px 10px rgba(24, 32, 38, 0.09);
    border: ${({ enabled, theme }) => enabled && `1px solid ${theme.colorsNew.product.dark30}`};
    transition: all ease-in-out 0.3s;
  }

  :not(:disabled):hover .productButton {
    background-color: ${({ enabled, theme }) =>
      enabled ? theme.colorsNew.product.dark30 : theme.colorsNew.product.regular};
    transition: all ease-in-out 0.3s;
    color: ${({ theme }) => theme.colorsNew.gray.white};
    box-shadow: none;
  }

  :not(:disabled):hover .sandboxButton {
    background-color: ${({ theme }) => theme.colorsNew.product.dark30};
    transition: all ease-in-out 0.3s;
    color: ${({ theme }) => theme.colorsNew.gray.white};
    box-shadow: none;
  }

  .boxButton {
    padding-top: 10px;
    border-top: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  a {
    text-decoration: none;
    text-align: center;
  }
`;

type ProductWrapperProps = {
  enabled: boolean;
  href: string;
  name: string;
  // identifier: string;
  // onAcquire: (name: string) => void;
  description: string;
};

const Square = styled.div<Pick<ButtonProps, 'enabled'>>`
  position: absolute;
  top: -30px;
  left: 180px;
  height: 316px;
  width: 305px;
  background: ${({ enabled, theme }) => (enabled ? theme.colorsNew.product.light : `#182026`)};
  mix-blend-mode: ${({ enabled }) => (enabled ? `normal` : `color-burn`)};
  opacity: ${({ enabled }) => (enabled ? `1` : `0.1`)};
  border-radius: 56px;
  transform: matrix(0.87, -0.54, 0.46, 0.86, 0, 0);
`;

const ProductButton = styled(Button)<Pick<ButtonProps, 'enabled'>>`
  background-color: ${({ enabled, theme }) =>
    enabled ? theme.colorsNew.product.regular : `transparent`};
  border: ${({ enabled, theme }) => !enabled && `1px solid ${theme.colorsNew.product.regular}`};
  color: ${({ enabled, theme }) =>
    enabled ? theme.colorsNew.gray.white : theme.colorsNew.product.regular};
  box-sizing: border-box;
  padding: 12px;
  font-size: 13px;
  letter-spacing: -0.003em;
  font-feature-settings:
    'tnum' on,
    'lnum' on;

  :not(:disabled):active {
    background-color: ${({ theme }) => theme.colorsNew.product.regular};
    color: ${({ theme }) => theme.colorsNew.gray.white};
  }

  :not(:disabled):focus {
    box-shadow: none;
  }
`;

const Product = ({
  enabled,
  href,
  name,
  // identifier,
  // onAcquire,
  description,
}: ProductWrapperProps) => {
  return (
    <ThemeProvider productName={name.toLowerCase() as ThemeProviderProps['productName']}>
      <ProductWrapper enabled={enabled}>
        <ProductDesc disabled={!enabled} productDesc={description} productName={name} />

        <div className="boxButton">
          {enabled ? (
            <ProductButton
              className="productButton"
              enabled={enabled}
              forwardedAs="a"
              href={href}
              target="_self"
            >
              Ingresar
            </ProductButton>
          ) : null}
        </div>
        <Square enabled={enabled} />
      </ProductWrapper>
    </ThemeProvider>
  );
};

export default Product;
