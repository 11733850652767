import { useState } from 'react';
import {
  Modal,
  ModalFooter,
  Button,
  ModalHeaderIconBox,
  ModalContent,
  H3,
  Spinner,
  Paragraph,
} from '@increasecard/typed-components';
import { WarningTriangleIcon } from '@increasecard/icons';
import { User } from '@increasecard/increase-core';
import { deleteUser } from '../services/UserService';
import { useTranslation } from 'react-i18next';

export interface DeleteUserModalProps {
  visible: boolean;
  user: User;
  onCancel: () => void;
  onDelete: () => void;
}

const MODAL_WIDTH = '400';

const noop = () => {
  return;
};

export function DeleteUserModal({ visible, user, onCancel, onDelete }: DeleteUserModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation('settingsUsers');

  const ERROR_TRANSLATIONS = {
    PF_1: t('errors.wrong_account'),
    PF_2: t('errors.yourself'),
    PF_3: t('errors.only_owner_admin'),
  };

  const getParsedError = (error: { code: string; description: string }) => {
    return ERROR_TRANSLATIONS[error.code as keyof typeof ERROR_TRANSLATIONS];
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      await deleteUser(user.id);
      setSuccess(true);
      onDelete();
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      setError(getParsedError(error.response.data.errors[0]));
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const getContent = () => {
    if (loading) {
      return <Spinner size={48} />;
    }
    if (error) {
      return (
        <Paragraph>
          Hubo un error intentando eliminar el usuario.
          <br />
          {error}
        </Paragraph>
      );
    }
    if (success) {
      return <Paragraph>Se eliminó el usuario correctamente</Paragraph>;
    }
    if (user) {
      return (
        <H3>
          Vas a eliminar acceso a {user.firstName} {user.lastName} <br />
          ¿estás seguro?
        </H3>
      );
    }
    return null;
  };
  return (
    <Modal onClose={onCancel} visible={visible} width={MODAL_WIDTH} onOk={noop} onCancel={onCancel}>
      <ModalHeaderIconBox
        colorPalette="alert"
        icon={<WarningTriangleIcon height="48" width="48" />}
      />
      <ModalContent align="center">{getContent()}</ModalContent>
      <ModalFooter align="center">
        {success || error ? (
          <Button buttonType="invisible" onClick={onCancel}>
            Volver
          </Button>
        ) : (
          <>
            <Button buttonType="alert" onClick={handleDeleteUser} disabled={loading}>
              Eliminar usuario
            </Button>
            <Button buttonType="invisible" onClick={onCancel} disabled={loading}>
              Cancelar
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}
