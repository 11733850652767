/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@increasecard/typed-components';

import PaymentMethodInputGroup from './PaymentMethodInputGroup';
import ButtonGroup from '../ButtonGroup';
import { Form, Formik } from 'formik';
import PaymentMethodSchema from '../../schemas/PaymentMethodSchema';
import { useTranslation } from 'react-i18next';

const propTypes = {
  availablePaymentMethods: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {};

function blankMetadata(type = 'PaymentMethods::CreditCard') {
  switch (type) {
    case 'PaymentMethods::CreditCard':
      return {
        cardholder_name: '',
        cardholder_document: '',
        number: '',
        expiration_month: '',
        expiration_year: '',
      };
    case 'PaymentMethods::Cbu':
      return { number: '' };
    case 'PaymentMethods::Placetopay':
      return {};
    default:
      throw new Error(`Unknown type ${type}`);
  }
}
export function blankPaymentMethod(type = 'PaymentMethods::CreditCard') {
  return {
    type,
    metadata: blankMetadata(type),
  };
}

export default function PaymentMethodCreateForm({
  availablePaymentMethods,
  cancelText,
  onCancel,
  onSubmit,
  submitText,
}) {
  const { t } = useTranslation('paymentMethodCreateForm');
  return (
    <Formik
      initialValues={blankPaymentMethod(availablePaymentMethods[0].type)}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={PaymentMethodSchema}
    >
      {({ handleChange, isValid, isSubmitting, setFieldValue, values }) => (
        <Form
          onChange={(e) => {
            if (e.target.name === 'type') {
              setFieldValue('metadata', blankMetadata(e.target.value));
            }
            handleChange(e);
          }}
        >
          <PaymentMethodInputGroup
            availablePaymentMethods={availablePaymentMethods}
            method={values}
          />
          {values.type !== 'PaymentMethods::Placetopay' && (
            <ButtonGroup>
              <Button disabled={isSubmitting || !isValid} type="submit">
                {submitText || t('save')}
              </Button>
              <Button buttonType="invisible" onClick={onCancel} type="button">
                {cancelText || t('cancel')}
              </Button>
            </ButtonGroup>
          )}
        </Form>
      )}
    </Formik>
  );
}

PaymentMethodCreateForm.propTypes = propTypes;
PaymentMethodCreateForm.defaultProps = defaultProps;
