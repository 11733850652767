import styled from 'styled-components';
import { ThemeProvider, ThemeProviderProps } from '@increasecard/typed-components';

type ProductProps = {
  disabled?: boolean;
  productName: string;
  productDesc: string;
};

const Product = styled.div<Pick<ProductProps, 'disabled' | 'productName'>>`
  display: flex;
  flex-direction: column;
  z-index: 1;
  .productName {
    display: flex;
    .increase,
    .product {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
    }
    .increase {
      color: ${({ theme }) => theme.colorsNew.gray.black};
    }
    .product {
      color: ${({ theme }) => theme.colorsNew.product.regular};
      margin-left: 3px;
    }
  }
  .productDesc {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.011em;
    margin: 8px 0px;
  }
`;

const ProductDesc = ({ productName, disabled, productDesc }: ProductProps) => {
  return (
    <ThemeProvider productName={productName?.toLowerCase() as ThemeProviderProps['productName']}>
      <Product disabled={disabled} productName={productName}>
        <div className="productName">
          <div className="product">{productName}</div>
        </div>
        <p className="productDesc">{productDesc}</p>
      </Product>
    </ThemeProvider>
  );
};

export default ProductDesc;
