import { Grid, InputText, Label, CellText, NativeSelect } from '@increasecard/typed-components';
import ProvinceSelect from '../ProvinceSelect/ProvinceSelect';
import { PrimaryButton } from './Buttons';
import { Form, Formik } from 'formik';
import PayBillingSettingsSchema from '../../schemas/PayBillingSettingsSchema';
import { capitalize } from '../../services/Strings';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PayPlan } from '../../models/PayPlan';
export interface PayBillingFormValues {
  legal_name: string;
  tax_id: string;
  email: string;
  address: {
    street: string;
    province: string;
    postal_code: string;
    country: string;
  };
  metadata: {
    tax_condition: string;
  };
  active_payment_method: { type: string; metadata: { number: string } };
  planId: string;
}

function sanitizeInitialValues(
  initialValues: Partial<PayBillingFormValues>,
  country: string,
): PayBillingFormValues {
  return {
    legal_name: initialValues.legal_name || '',
    tax_id: initialValues.tax_id || '',
    email: initialValues.email || '',
    address: {
      ...initialValues.address,
      street: (initialValues.address && initialValues.address.street) || '',
      province: (initialValues.address && initialValues.address.province) || '',
      postal_code: (initialValues.address && initialValues.address.postal_code) || '',
      country: (initialValues.address && initialValues.address.country) || capitalize(country),
    },
    metadata: {
      tax_condition:
        (initialValues.metadata && initialValues.metadata.tax_condition) || 'RESPONSABLE_INSCRIPTO',
    },
    active_payment_method: initialValues.active_payment_method || {
      type: 'PaymentMethods::Cbu',
      metadata: { number: '' },
    },
    planId: initialValues.planId || '',
  };
}
export interface PaySettingsBillingForm {
  initialValues: PayBillingFormValues;
  onSubmit: (values: PayBillingFormValues, overwriteTaxId: boolean) => void;
  country: string;
  taxIdAccount: string;
  plans: PayPlan[];
  pricingUrl: string;
}

const StyledLabel = styled(Label)`
  font-weight: 700;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorMessage = (inputName: string, errors: any, touched: any) => {
  if (!touched[inputName]) {
    return undefined;
  }
  if (errors[inputName]) {
    return errors[inputName];
  }
  return undefined;
};

export default function PaySettingsBillingForm({
  initialValues,
  country,
  onSubmit,
  plans,
  pricingUrl,
}: PaySettingsBillingForm) {
  const { t } = useTranslation('billing');

  return (
    <Formik
      initialValues={sanitizeInitialValues(initialValues, country)}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values, true);
        setSubmitting(false);
      }}
      validationSchema={PayBillingSettingsSchema}
    >
      {({ values, handleChange, errors, touched, handleBlur, isSubmitting, isValid }) => (
        <Form onChange={handleChange}>
          <Grid rowGap={3}>
            <InputText
              className="span-mobile-2 span-desktop-6"
              errorMessage={errorMessage('legal_name', errors, touched)}
              id="legal_name"
              label="Razón social"
              name="legal_name"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="text"
              value={values.legal_name}
            />
            <InputText
              className="span-mobile-2 span-desktop-6"
              errorMessage={errorMessage('tax_id', errors, touched)}
              id="tax_id"
              label="cuit/cuil"
              name="tax_id"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="text"
              value={values.tax_id}
            />
            <NativeSelect
              className="span-mobile-2 span-desktop-6"
              errorMessage={
                touched.metadata?.tax_condition ? errors.metadata?.tax_condition : undefined
              }
              id="metadata.tax_condition"
              label="Condición ante el iva"
              name="metadata.tax_condition"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.metadata.tax_condition}
            >
              <option defaultChecked value="RESPONSABLE_INSCRIPTO">
                {t('taxConditionResponsableInscripto')}
              </option>
              <option value="EXENTO">{t('taxConditionExento')}</option>
              <option value="CONSUMIDOR_FINAL">{t('taxConditionConsumidorFinal')}</option>
              <option value="MONOTRIBUTO">{t('taxConditionMonotributo')}</option>
            </NativeSelect>
            <NativeSelect
              className="span-mobile-2 span-desktop-6"
              errorMessage={touched.address?.country ? errors.address?.country : undefined}
              id="address.country"
              label="País"
              name="address.country"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.address.country}
            >
              <option defaultChecked value="">
                Seleccionar
              </option>
              <option value="argentina">Argentina</option>
              <option value="ecuador">Ecuador</option>
              <option value="peru">Peru</option>
              <option value="uruguay">Uruguay</option>
            </NativeSelect>
            <ProvinceSelect
              label=""
              className="span-mobile-2 span-desktop-6"
              country={
                values.address.country as
                  | 'argentina'
                  | 'chile'
                  | 'colombia'
                  | 'dominicana'
                  | 'ecuador'
                  | 'peru'
                  | 'uruguay'
              }
              errorMessage={touched.address?.province ? errors.address?.province : undefined}
              id="address.province"
              name="address.province"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.address.province}
            />
            <InputText
              className="span-mobile-2 span-desktop-6"
              errorMessage={touched.address?.street && errors.address?.street}
              id="address.street"
              label={t('taxAddress')}
              name="address.street"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.address.street}
            />
            <InputText
              className="span-mobile-2 span-desktop-6"
              errorMessage={touched.address?.postal_code && errors.address?.postal_code}
              id="address.postal_code"
              label="Código postal"
              name="address.postal_code"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.address.postal_code}
            />
            <InputText
              className="span-mobile-2 span-desktop-6"
              errorMessage={errorMessage('email', errors, touched)}
              id="email"
              label="Email de facturación"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
            />
            <NativeSelect
              className="span-mobile-2 span-desktop-12"
              id="planId"
              label="PLANES INCREASE PAY"
              name="planId"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Selecciona tu plan"
              required
              value={values.planId}
            >
              <option defaultChecked value="">
                Selecciona tu plan
              </option>
              {plans !== null &&
                plans.map((plan: PayPlan) => {
                  return (
                    <option key={plan.id} value={plan.id}>
                      {plan.name}
                    </option>
                  );
                })}
            </NativeSelect>
            <CellText>
              Conocé más acerca de los planes haciendo click{' '}
              <a href={pricingUrl} rel="noopener noreferrer" target="_blank">
                acá
              </a>
            </CellText>
            <StyledLabel>Método de pago</StyledLabel>
            <InputText
              id="active_payment_method.metadata.number"
              label="Número de CBU"
              name="active_payment_method.metadata.number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.active_payment_method.metadata.number}
            />
            <PrimaryButton disabled={isSubmitting || !isValid} type="submit">
              Continuar
            </PrimaryButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
