import * as Yup from 'yup';

export default Yup.object({
  first_name: Yup.string().required('Campo obligatorio'),
  last_name: Yup.string().required('Campo obligatorio'),
  email: Yup.string().email('Email incorrecto').required('Campo obligatorio'),
  authorizations: Yup.array().of(
    Yup.object({
      role: Yup.string().required('Campo obligatorio'),
    }),
  ),
});
