import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  justify-content: left;
  padding-bottom: 20px;
  margin: 36px auto 0 auto;
  scrollbar-width: none;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
    padding-bottom: 34px;
    width: 100%;
    justify-content: ${({ variant }) => (variant > 3 ? `left` : `center`)};
    scrollbar-width: ${({ variant }) => (variant < 4 ? `none` : `auto`)};
  }
`;

const BorderBottom = styled.div`
  width: 95%;
  margin: 0 auto;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNew.increase.dark70};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;

const Card = styled.div`
  flex-shrink: 0;
`;

const Carousel = ({ children }) => {
  if (!children || !Array.isArray(children)) return null;
  const variant = children.filter((c) => c !== null);
  return (
    <>
      <Container variant={variant.length}>
        {children.map((c) => (
          <Card key={c}>{c}</Card>
        ))}
      </Container>
      <BorderBottom />
    </>
  );
};

export default Carousel;
