import React from 'react';
import { Formik, Form } from 'formik';
import { Grid } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, InvisibleButton } from './Buttons';
import CompanyInputGroup from './CompanyInputGroup';
import ButtonGroup from './ButtonGroup';
import billingSettingsSchema from '../schemas/BillingSettingsSchema';

export interface BillingFormValues {
  legal_name: string;
  tax_id: string;
  email: string;
  address: {
    street: string;
    province: string;
    postal_code: string;
    country: string;
  };
  metadata: {
    tax_condition: string;
  };
}

function sanitizeInitialValues(
  initialValues: Partial<BillingFormValues>,
  country: string,
  taxIdAccount: string,
): BillingFormValues {
  return {
    legal_name: initialValues.legal_name || '',
    tax_id: initialValues.tax_id || taxIdAccount || '',
    email: initialValues.email || '',
    address: {
      ...initialValues.address,
      street: (initialValues.address && initialValues.address.street) || '',
      province: (initialValues.address && initialValues.address.province) || '',
      postal_code: (initialValues.address && initialValues.address.postal_code) || '',
      country: (initialValues.address && initialValues.address.country) || country,
    },
    metadata: {
      tax_condition:
        (initialValues.metadata && initialValues.metadata.tax_condition) || 'RESPONSABLE_INSCRIPTO',
    },
  };
}

export interface BillingSettingsFormProps {
  initialValues: BillingFormValues;
  onSubmit: (values: BillingFormValues, overwriteTaxId: boolean) => void;
  onCancel: () => void;
  submitText: string;
  cancelText: string;
  country: string;
  taxIdAccount: string;
}

export default function BillingSettingsForm({
  initialValues,
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  country,
  taxIdAccount,
}: BillingSettingsFormProps) {
  const { t: billingValidateTranslations } = useTranslation('validation');
  const taxIdCompany = initialValues.tax_id;
  return (
    <React.Fragment>
      <Formik
        initialValues={sanitizeInitialValues(initialValues, country, taxIdAccount)}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values, true);
          setSubmitting(false);
        }}
        validationSchema={billingSettingsSchema(billingValidateTranslations)}
      >
        {({ errors, values, isSubmitting, isValid, handleBlur, handleChange, touched }) => (
          <React.Fragment>
            <Form onChange={handleChange}>
              <Grid rowGap={3}>
                <CompanyInputGroup
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  taxIdCompany={taxIdCompany}
                />
                <ButtonGroup>
                  <PrimaryButton disabled={isSubmitting || !isValid} type="submit">
                    {submitText}
                  </PrimaryButton>
                  <InvisibleButton buttonType="invisible" onClick={onCancel} type="reset">
                    {cancelText}
                  </InvisibleButton>
                </ButtonGroup>
              </Grid>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
}
