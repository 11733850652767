import ProvinceSelect from './ProvinceSelect/ProvinceSelect';
import { useTranslation } from 'react-i18next';
import { InputText, Grid, NativeSelect } from '@increasecard/typed-components';
import { BillingFormValues } from './BillingSettingsForm';
import { TaxIdInput } from './TaxIdInput';
import { errorMessage } from '../helpers/errorsHelper';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';

export interface CompanyInputGroupProps {
  errors: FormikErrors<BillingFormValues>;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  touched: FormikTouched<BillingFormValues>;
  values: BillingFormValues;
  taxIdCompany: string;
}

const validCountries = [
  { phoneCode: '+54', name: 'Argentina', codeName: 'argentina' },
  { phoneCode: '+56', name: 'Chile', codeName: 'chile' },
  { phoneCode: '+57', name: 'Colombia', codeName: 'colombia' },
  { phoneCode: '+593', name: 'Ecuador', codeName: 'ecuador' },
  { phoneCode: '+52', name: 'México', codeName: 'mexico' },
  { phoneCode: '+51', name: 'Perú', codeName: 'peru' },
  { phoneCode: '+1', name: 'República Dominicana', codeName: 'dominicana' },
  { phoneCode: '+598', name: 'Uruguay', codeName: 'uruguay' },
];

export default function CompanyInputGroup({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
}: CompanyInputGroupProps) {
  const { t } = useTranslation('billing');
  const country = values.address && values.address.country.toLowerCase();

  return (
    <Grid rowGap={3}>
      <InputText
        className="grid-span-desktop-4"
        errorMessage={errorMessage('legal_name', errors, touched)}
        id="legal_name"
        label={t('legalName')}
        name="legal_name"
        onBlur={handleBlur}
        onChange={handleChange}
        required
        value={values.legal_name}
      />
      <TaxIdInput
        className="grid-span-desktop-4"
        country={country}
        disabled={false}
        errorMessage={errorMessage('tax_id', errors, touched)}
        id="tax_id"
        label={t('taxId')}
        name="tax_id"
        onBlur={handleBlur}
        onChange={handleChange}
        required
        type="text"
        value={values.tax_id}
      />

      {country === 'argentina' && (
        <NativeSelect
          className="grid-span-desktop-4"
          id="metadata.tax_condition"
          label={t('taxCondition')}
          name="metadata.tax_condition"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.metadata.tax_condition}
        >
          <option defaultChecked value="RESPONSABLE_INSCRIPTO">
            {t('taxConditionResponsableInscripto')}
          </option>
          <option value="EXENTO">{t('taxConditionExento')}</option>
          <option value="CONSUMIDOR_FINAL">{t('taxConditionConsumidorFinal')}</option>
          <option value="MONOTRIBUTO">{t('taxConditionMonotributo')}</option>
        </NativeSelect>
      )}

      <NativeSelect
        className="grid-span-desktop-6"
        disabled
        id="address.country"
        label={t('common:country')}
        name="address.country"
        required
        value={country}
      >
        {validCountries.map((c) => (
          <option key={c.phoneCode} value={c.codeName}>
            {c.name}
          </option>
        ))}
      </NativeSelect>

      <ProvinceSelect
        className="grid-span-desktop-6"
        country={
          country as
            | 'argentina'
            | 'chile'
            | 'colombia'
            | 'dominicana'
            | 'ecuador'
            | 'peru'
            | 'uruguay'
        }
        id="address.province"
        name="address.province"
        onBlur={handleBlur}
        onChange={handleChange}
        label=""
        required
        value={values.address.province}
      />
      <InputText
        className="grid-span-desktop-9"
        errorMessage={errorMessage('address.street', errors, touched)}
        id="address.street"
        label={t('taxAddress')}
        name="address.street"
        onBlur={handleBlur}
        onChange={handleChange}
        required
        value={values.address.street}
      />
      <InputText
        className="grid-span-desktop-3"
        errorMessage={errorMessage('address.postal_code', errors, touched)}
        id="address.postal_code"
        label={t('zipCode')}
        maxLength="4"
        name="address.postal_code"
        onBlur={handleBlur}
        onChange={handleChange}
        required
        value={values.address.postal_code}
      />

      <InputText
        className="grid-span-desktop-6"
        errorMessage={errorMessage('email', errors, touched)}
        id="email"
        label={t('billingEmail')}
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        type="email"
        value={values.email}
      />
    </Grid>
  );
}
