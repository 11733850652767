import { InputText, Grid } from '@increasecard/typed-components';

export default function CbuCreateForm(
  props: Omit<typeof InputText.arguments, 'className' | 'label' | 'name'>,
) {
  return (
    <Grid>
      <InputText
        className="grid-span-desktop-6"
        id="metadata.number"
        label="Número de CBU"
        name="metadata.number"
        {...props}
      />
    </Grid>
  );
}
