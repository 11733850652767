import { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@increasecard/typed-components';
import { capitalize } from '../services/Strings';
import RadioButton from './RadioButton';

export type Score = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';

export interface FormState {
  score: null | Score;
  comment?: string;
}

export interface NPSSurveyProps {
  className?: string;
  onSubmit: (f: FormState) => void;
  productName: string;
}
const VALUES: Score[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

function NPSSurvey({ className, onSubmit, productName }: NPSSurveyProps) {
  const [form, setForm] = useState<FormState>({ score: null, comment: undefined });
  const productDisplayName = `Increase${capitalize(productName)}`;
  return (
    <div className={className}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit(form);
        }}
      >
        <h1>
          ¿Cuán probable es que recomiendes <strong>{productDisplayName}</strong> a un familiar o
          conocido?
        </h1>
        <fieldset className="score_input_group">
          {VALUES.map((value) => (
            <RadioButton
              checked={form.score === value}
              key={value}
              label={value}
              name="score"
              onChange={() => setForm((prevForm) => ({ ...prevForm, score: value }))}
              required={true}
              value={value}
            />
          ))}
        </fieldset>
        <label className="comment">
          En base a tu puntuación, ¿cómo te gustaría que mejoremos tu experiencia? (Opcional)
          <textarea
            name="comment"
            onChange={(event) => {
              const value = event.target.value;
              setForm((prevForm) => ({ ...prevForm, comment: value }));
            }}
            value={form.comment}
          />
        </label>
        <Button type="submit">Enviar</Button>
      </form>
    </div>
  );
}

export default styled(NPSSurvey)`
  color: #1e1e1e;
  h1 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
  }
  form {
    max-width: 480px;
  }

  .score_input_group {
    border: none;
    padding: 0;
    margin: 40px 0 20px;
    font-size: 13px;
    position: relative;

    label + label {
      margin-left: 4px;
    }
  }

  .score_input_group::after {
    content: 'Muy probable';
    font-size: 10px;
    line-height: 15px;
    text-transform: uppercase;
    position: absolute;
    right: 0;
    top: -23px;
    letter-spacing: 0.9px;
    font-weight: bold;
    color: #828690;
  }

  .score_input_group::before {
    content: 'Nada probable';
    font-size: 10px;
    line-height: 15px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: -23px;
    letter-spacing: 0.9px;
    font-weight: bold;
    color: #828690;
  }

  .comment {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    textarea {
      margin-top: 8px;
      padding: 8px;
    }
  }
`;
