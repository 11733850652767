import * as Yup from 'yup';
const RequiredString = (translations) => Yup.string().required(translations('required'));
const ExactLength = (exactLength, translations) =>
  RequiredString(translations).length(exactLength, translations('len_exact', { len: exactLength }));

const TaxIdSchemas = {
  argentina: (translations) =>
    ExactLength(11, translations).matches(
      /^([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})$/g,
      translations('tax_id.argentina'),
    ),
  chile: (translations) => ExactLength(9, translations),
  colombia: (translations) =>
    RequiredString(translations)
      .min(9, translations('len_min', { len: 9 }))
      .max(10, translations('len_max', { len: 10 })),
  dominicana: (translations) => ExactLength(9, translations),
  ecuador: (translations) =>
    ExactLength(13, translations).matches(
      /^[0-2][0-9][6|9][0-9]{7}001$/,
      translations('tax_id.ecuador'),
    ),
  peru: (translations) => ExactLength(11, translations),
  uruguay: (translations) => ExactLength(12, translations),
};

export default function billingSettingsSchema(translations) {
  return Yup.object({
    legal_name: RequiredString(translations),
    tax_id: RequiredString(translations).when('address.country', (country) => {
      if (Object.keys(TaxIdSchemas).includes(country)) {
        return TaxIdSchemas[country](translations);
      }
      console.error(`tax_id schema not found for country: [${country}]`);
      return RequiredString(translations);
    }),
    metadata: Yup.object({
      tax_condition: RequiredString(translations).oneOf([
        'RESPONSABLE_INSCRIPTO',
        'EXENTO',
        'CONSUMIDOR_FINAL',
        'MONOTRIBUTO',
      ]),
    }),
    address: Yup.object({
      country: RequiredString(translations),
      province: RequiredString(translations),
      street: RequiredString(translations),
      postal_code: RequiredString(translations),
    }),
    email: RequiredString(translations).email(translations('email')),
  });
}
