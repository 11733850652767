import React, { useState, FormEvent } from 'react';
import styled from 'styled-components';
import { InputText, Grid, NativeSelect } from '@increasecard/typed-components';
import { PrimaryButton } from './Buttons';

type UserInviteFormProps = {
  className?: string;
  onSubmit: (form: { email: string; role: number }) => void;
};

const UserInviteForm: React.FC<UserInviteFormProps> = ({ className, onSubmit, ...rest }) => {
  const [inviteForm, setInviteForm] = useState({ email: '', role: 0 });
  //TODO: Change this form to formik
  return (
    <Grid
      as="form"
      className={className}
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(inviteForm);
      }}
      style={{ marginTop: 20, alignItems: 'flex-end' }}
      {...rest}
    >
      <InputText
        className="grid-span-desktop-6"
        id="email"
        label="Email"
        name="email"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setInviteForm({ ...inviteForm, email: event.target.value })
        }
        type="email"
        value={inviteForm.email}
      />
      <NativeSelect
        className="grid-span-desktop-3"
        id="role"
        label="Tipo de usuario"
        name="role"
        onChange={(event) => setInviteForm({ ...inviteForm, role: Number(event.target.value) })}
        value={inviteForm.role}
      >
        <option value="0">Colaborador</option>
        <option value="80">Administrador</option>
      </NativeSelect>
      <PrimaryButton className="grid-span-desktop-3" type="submit">
        Invitar usuario
      </PrimaryButton>
    </Grid>
  );
};

export default styled(UserInviteForm)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
