import styled from 'styled-components';

type IncreaseLogoProps = {
  color?: string;
  size?: string;
};

function IncreaseLogo({ color, size }: IncreaseLogoProps) {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 42 42"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.30176 42.2437C6.83562 42.2407 4.47132 41.2597 2.72753 39.5158C0.983735 37.772 0.00283328 35.4076 0 32.9415L0 9.54418C0.00294286 7.07822 0.983932 4.71411 2.72775 2.97053C4.47157 1.22694 6.8358 0.246268 9.30176 0.243652L32.6987 0.243652C35.1646 0.246159 37.5289 1.22681 39.2726 2.97043C41.0164 4.71405 41.9973 7.07822 42 9.54418V32.9415C41.9974 35.4076 41.0166 37.772 39.2729 39.5159C37.5291 41.2598 35.1648 42.2408 32.6987 42.2437H9.30176ZM2.28818 9.54418V32.9415C2.29014 34.8011 3.02967 36.5839 4.34453 37.899C5.65938 39.214 7.44217 39.9537 9.30176 39.9559H32.6987C34.5581 39.9536 36.3407 39.2138 37.6553 37.8988C38.97 36.5837 39.7093 34.8009 39.711 32.9415V9.54418C39.709 7.68495 38.9696 5.90243 37.655 4.58772C36.3404 3.27301 34.5579 2.53349 32.6987 2.53142H9.30176C7.44239 2.53338 5.65972 3.27283 4.34487 4.58752C3.03001 5.90223 2.29036 7.68481 2.28818 9.54418ZM27.9881 30.6998L21.0124 16.3284L14.1569 30.699H9.65794L18.8201 11.7871H23.1795L32.3416 30.6998H27.9881Z"
        fill={color}
      />
    </svg>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  background-color: #05926d;
  padding: 40px;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  @media (min-width: 1100px) {
    width: 260px;
    justify-content: flex-start;
  }
`;

interface SquareProps {
  width?: string;
  height?: string;
  left?: string;
  top?: string;
  border?: string;
  borderRadius?: string;
  bgVariant?: string;
  borderVariant?: string;
  mobileLeft?: string;
  mobileTop?: string;
}

const Square = styled.div<SquareProps>`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  top: ${(props) => props.mobileTop};
  left: ${(props) => props.mobileLeft};
  border: ${function ({ theme, border, borderVariant }) {
    switch (borderVariant) {
      case 'light':
        return `${border} ${theme.colorsNew.increase.light}`;
      case 'accent':
        return `${border} ${theme.colorsNew.increase.accent}`;
      default:
        return `none`;
    }
  }};
  background: ${function ({ theme, bgVariant }) {
    switch (bgVariant) {
      case 'fill':
        return `${theme.colorsNew.increase.dark50}`;
      default:
        return `transparent`;
    }
  }};
  border-radius: ${(props) => props.borderRadius || '0px'};
  mix-blend-mode: normal;
  box-sizing: border-box;
  transform: rotate(60deg);
  @media (min-width: 1100px) {
    top: ${(props) => props.top};
    left: ${(props) => props.left};
  }
`;

export const IncreaseSidebar = () => {
  return (
    <Container>
      <div style={{ zIndex: 1 }}>
        <IncreaseLogo color="#ffffff" size="60" />
      </div>
      <Square
        border="1px solid"
        borderRadius="13px"
        borderVariant="light"
        height="63.07px"
        left="210px"
        mobileLeft="320px"
        mobileTop="90px"
        top="75px"
        width="63.07px"
      />
      <Square
        bgVariant="fill"
        borderRadius="90px"
        height="623.05px"
        left="-410px"
        mobileLeft="-230px"
        mobileTop="60px"
        top="-60px"
        width="623.05px"
      />
      <Square
        border="2px solid"
        borderRadius="56px"
        borderVariant="accent"
        height="377.4px"
        left="-210.54px"
        mobileLeft="-350px"
        mobileTop="-210px"
        top="300px"
        width="377.4px"
      />
    </Container>
  );
};
