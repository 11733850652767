import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { Button, Link } from '@increasecard/typed-components';
import logosTarjetas from '../../images/placetopay-tarjetas-logos@2x.png';
import placetoPayLogo from '../../images/placetopay-logo.svg';
import { Popup } from '../../icons/icons';
import useToggle from '../../hooks/useToggle';
import Modal from '../Modal';
import ButtonGroup from '../ButtonGroup';
import { IncreaseLogo } from '@increasecard/icons';
import { startPlacetoPaySubscription } from '../../services/PlacetoPayService';
import usePriceToPay from '../../hooks/usePriceToPay';
import LoadingIndicator from '../LoadingIndicator';
import { useUser } from '@increasecard/increase-core';
const ToggleScrollGlobalStyle = createGlobalStyle`
  html {
    overflow: ${(props) => (props.on ? 'unset' : 'hidden')};
  }
`;

const LinkStyled = styled(Link)`
  white-space: nowrap;
`;

export default function PlaceToPayCreateForm() {
  const { value: modalOpen, toggle: toggleModalOpen } = useToggle();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img alt="Logo de PlacetoPay" src={placetoPayLogo} style={{ marginRight: '16px' }} />
      <LinkStyled
        href="#"
        onClick={(event) => {
          toggleModalOpen();
          event.preventDefault();
        }}
        style={{ textDecoration: 'underline' }}
      >
        Vincular medio de pago con PlacetoPay <img alt="Abre en un popup" src={Popup} />
      </LinkStyled>
      <StyledPlaceToPayModal onToggleOpen={toggleModalOpen} open={modalOpen} />
    </div>
  );
}
function PlaceToPayModal({ className, open, onToggleOpen }) {
  const [tcAccepted, setTcAccepted] = useState(false);
  const user = useUser();
  const quantity = user.productQuantity('card', 'ecuador');
  const priceToPay = usePriceToPay({ productName: 'card', country: 'ecuador', units: quantity });
  if (priceToPay === null || user === null) return <LoadingIndicator />;
  const BodyPortal = ({ children }) => createPortal(children, document.querySelector('body'));
  return (
    <BodyPortal>
      <Modal className={className} hidden={!open}>
        <ToggleScrollGlobalStyle on={!open} />
        <div className="modal-body">
          <img alt="Logo de Increase" src={IncreaseLogo} />
          <div className="price">
            <div>U$D ${priceToPay.total} por mes</div>
            <small>(IVA incluido)</small>
          </div>
          <div className="info">
            <small>Tarjetas aceptadas</small>
            <img alt="Tarjetas aceptadas" height="48" src={logosTarjetas} />
          </div>
          <div className="info">
            <small style={{ marginBottom: '5px' }}>Procesado por</small>
            <a href="http://www.placetopay.com" rel="noopener noreferrer" target="_blank">
              <img
                alt=""
                height="32"
                src="https://dev.placetopay.com/web/wp-content/uploads/2019/02/p2p-logo_Black.svg"
              />
            </a>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              startPlacetoPaySubscription();
            }}
          >
            <label>
              <input
                checked={tcAccepted}
                onChange={(event) => setTcAccepted(event.target.checked)}
                required
                type="checkbox"
              />
              He leído y acepto los &nbsp;
              <a
                href="https://increase.app/terminos-y-condiciones"
                rel="noopener noreferrer"
                target="_blank"
              >
                términos y condiciones
              </a>
            </label>
            <ButtonGroup>
              <Button disabled={!tcAccepted} type="submit">
                Agregar tarjeta
              </Button>
              <Button
                buttonType="invisible"
                onClick={(event) => {
                  event.preventDefault();
                  onToggleOpen();
                }}
              >
                Cancelar
              </Button>
            </ButtonGroup>
          </form>
        </div>
      </Modal>
    </BodyPortal>
  );
}
const StyledPlaceToPayModal = styled(PlaceToPayModal)`
  .modal-body {
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    min-height: 250px;
    padding: 32px;
  }

  .modal-body > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .price {
    font-size: 18px;
    font-weight: 700;
  }

  .price small {
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .info small {
    font-size: 10px;
    color: #a9adb7;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 700;
  }

  form label {
    display: block;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  form label input {
    margin-right: 4px;
  }
`;
