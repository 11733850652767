import React, { useEffect } from 'react';
import { LangCode, setLocale } from '../i18n/index';
import { useUser } from '@increasecard/increase-core';

const countryToLangCode = (countryName: string): LangCode => {
  switch (countryName) {
    case 'argentina':
      return 'es-AR';
    case 'chile':
      return 'es-CL';
    case 'dominicana':
      return 'es-DO';
    case 'ecuador':
      return 'es-EC';
    case 'peru':
      return 'es-PE';
    case 'uruguay':
      return 'es-UY';
    default:
      console.error('Missing langCode for country', countryName);
      return 'es';
  }
};

/**
 * Initializes the translations service, making it load current's user
 * language before rendering any other component.
 *
 * @param children the elements whose render will be blocked during translations retrieval.
 */
export default function I18nLoader({ children }: { children: React.ReactNode }) {
  const user = useUser();

  useEffect(() => {
    if (!user) return;
    setLocale(countryToLangCode(user.defaultCountry));
  }, [user]);

  return <>{children}</>;
}
