import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  TableWrapper,
  Table,
  THead,
  Td,
  Th,
  TableRow,
  Link,
  CellText,
  InputLabel,
  MenuButton,
  MenuItem,
} from '@increasecard/typed-components';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DeleteUserModal } from '../components/DeleteUserModal';
import { useModal } from '../hooks/useModal';
import { getUsers } from '../services/UserService';
import LoadingIndicator from '../components/LoadingIndicator';

const LabelCellText = styled(InputLabel)`
  font-weight: bold;
  color: ${({ theme, status }) =>
    status === 'failed' || status === 'pending'
      ? `${theme.colorsNew.coralAlert.regular}`
      : status === 'sent'
      ? `#D29249`
      : `${theme.colorsNew.gray.grayBold}`};
`;

const propTypes = {
  activeUser: PropTypes.object,
  invitations: PropTypes.arrayOf(PropTypes.object),
  onRemoveInvitation: PropTypes.func,
  onInvitationResend: PropTypes.func,
  activeUserCanRemove: PropTypes.bool,
};

const defaultProps = {};

function useUsers() {
  const [users, setUsers] = useState(null);
  useEffect(() => {
    getUsers().then(setUsers);
  }, []);
  return { users, setUsers };
}

export function UsersTable({
  activeUser,
  invitations,
  onRemoveInvitation,
  onInvitationResend,
  activeUserIsAdmin,
}) {
  const { t } = useTranslation('settingsUsers');
  const deleteUserModal = useModal();
  const { users, setUsers } = useUsers();
  const history = useHistory();

  const loadingUsers = users === null;

  const onDeleteUser = async () => {
    // update users table
    const users = await getUsers();
    setUsers(users);
  };

  const STATUS_TRANSLATIONS = {
    sent: t('invitation_status.pending'),
    // PlatformAPI saves invitations as "pending" when it couldn't send them
    pending: t('invitation_status.failed'),
    // not found "failed" invitations on metabase, what is this status for?
    failed: t('invitation_status.failed'),
  };

  const ROLES_TRANSLATIONS = {
    viewer: t('roles.viewer'),
    admin: t('roles.admin'),
  };

  const notAcceptedInvitations = invitations.filter((i) => i.status in STATUS_TRANSLATIONS);

  const invitationsActions = [
    {
      label: t('actions.remove_invitation'),
      cb: onRemoveInvitation,
      variant: 'alert',
      disabled: !activeUserIsAdmin,
    },
  ];

  const handleDeleteUserModal = (user) => {
    deleteUserModal.open(user);
  };

  const goToUsersSettings = (user) => {
    history.push(`/advanced/settings/users/${user.id}`);
  };

  const usersActions = [
    {
      label: t('actions.remove_user'),
      cb: handleDeleteUserModal,
      variant: 'alert',
      disabled: (user) => {
        // activeUser can't delete himself and can't delete the owner user
        return user.id === activeUser.id || user.owner || !activeUserIsAdmin;
      },
    },
    {
      label: t('actions.edit_user'),
      cb: goToUsersSettings,
      variant: 'info',
      disabled: () => {
        return !activeUserIsAdmin;
      },
    },
  ];
  return (
    <>
      {loadingUsers ? (
        <LoadingIndicator />
      ) : (
        <TableWrapper>
          <Table style={{ fontSize: '13px' }} zebra>
            <THead>
              <tr>
                <Th>{t('user')}</Th>
                <Th>{t('email')}</Th>
                <Th>{t('status')}</Th>
                <Th>{t('role_type')}</Th>
                <Th rightAligned />
                <Th rightAligned />
              </tr>
            </THead>
            <tbody>
              {notAcceptedInvitations &&
                notAcceptedInvitations.map((invitation) => {
                  return (
                    <TableRow double key={invitation.id}>
                      <Td />
                      <Td>{invitation.email}</Td>
                      <Td>
                        <LabelCellText status={invitation.status}>
                          {STATUS_TRANSLATIONS[invitation.status]}
                        </LabelCellText>
                        <CellText>
                          {invitation.status === 'sent' ? (
                            <Link
                              onClick={() => onInvitationResend(invitation)}
                              style={{ fontSize: '13px' }}
                            >
                              Reenviar invitación
                            </Link>
                          ) : (
                            // we are showing pending invitations as error because platformAPI doesn't change the invitation status
                            invitation.status === 'pending' && t('error_description')
                          )}
                        </CellText>
                      </Td>
                      <Td>
                        <LabelCellText>{t('role')}</LabelCellText>
                        <CellText>{ROLES_TRANSLATIONS[invitation.role]}</CellText>
                      </Td>
                      <Td rightAligned />
                      <Td rightAligned>
                        <MenuButton>
                          {({ toggleShowMenu }) =>
                            invitationsActions.map(({ label, cb, variant, disabled }) => (
                              <MenuItem
                                disabled={disabled}
                                key={label}
                                onClick={() => {
                                  toggleShowMenu();
                                  cb(invitation);
                                }}
                                variant={variant}
                              >
                                {label}
                              </MenuItem>
                            ))
                          }
                        </MenuButton>
                      </Td>
                    </TableRow>
                  );
                })}
              {users &&
                users.map((user) => {
                  const listedUserIsOwner = user.owner;
                  const platformRole = user.authorizations.find(
                    (a) => a.product_name === 'platform'
                  )?.role;
                  return (
                    <Fragment key={user.id}>
                      <TableRow double >
                        <Td>
                          <CellText weight="bold">
                            {user.firstName} {user.lastName}
                          </CellText>
                        </Td>
                        <Td>{user.email}</Td>
                        <Td>
                          <LabelCellText>{t('invitation_status.accepted')}</LabelCellText>
                          <CellText>{format(user.createdAt, 'dd/MM/yyyy')}</CellText>
                        </Td>
                        <Td>
                          <LabelCellText>{t('role')}</LabelCellText>
                          <CellText>
                            {listedUserIsOwner
                              ? t('roles.owner')
                              : ROLES_TRANSLATIONS[platformRole]}
                          </CellText>
                        </Td>
                        <Td rightAligned />
                        <Td rightAligned>
                          <MenuButton>
                            {({ toggleShowMenu }) =>
                              usersActions.map(({ label, cb, variant, disabled }) => (
                                <MenuItem
                                  disabled={disabled(user)}
                                  key={label}
                                  onClick={() => {
                                    toggleShowMenu();
                                    cb(user);
                                  }}
                                  variant={variant}
                                >
                                  {label}
                                </MenuItem>
                              ))
                            }
                          </MenuButton>
                        </Td>
                      </TableRow>
                    </Fragment>
                  );
                })}
            </tbody>
          </Table>
        </TableWrapper>
      )}
      <DeleteUserModal
        user={deleteUserModal.data}
        visible={deleteUserModal.isOpen}
        onCancel={deleteUserModal.close}
        onDelete={onDeleteUser}
      />
    </>
  );
}

UsersTable.propTypes = propTypes;
UsersTable.defaultProps = defaultProps;
