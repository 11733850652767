import { useEffect, useState } from 'react';
import { strictTranslate } from '../i18n/index';
import { ApiError } from '../types/ApiError';
import InlineAlert from './InlineAlert';

export interface ApiErrorMessageProps {
  error: ApiError | null;
  marginTop?: number;
}

export function ApiErrorMessage({ error, marginTop }: ApiErrorMessageProps): JSX.Element | null {
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    // show alert if error changed
    setClosed(false);
  }, [error]);

  if (!error || closed) return null;

  function onAlertClose() {
    setClosed(true);
  }

  // reports an error if the key is not found
  const message = strictTranslate(
    `api:errors.${error.code}`,
    {
      message: `No message for error code ${error.code}`,
    },
    'api:errors.unexpected_error_message',
  );

  const styleProp =
    marginTop && marginTop > 0 ? { marginTop: `${marginTop}px`, marginBottom: '16px' } : undefined;

  return <InlineAlert message={message} onClose={onAlertClose} style={styleProp} variant="alert" />;
}
