import { ChangeEvent } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { InfoIcon } from '@increasecard/icons';
import { CellText, InputText, H3, Caption, NativeSelect } from '@increasecard/typed-components';
import { PrimaryButton } from './Buttons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import UserSettingsSchema from '../schemas/UserSettingsSchema';
import { ScreenSeparator } from '../components/common/ScreenSeparator';
import { UserAuthorization } from '@increasecard/increase-core';

function sanitizeInitialValues(initialValues: Partial<Values>): Values {
  return {
    first_name: initialValues.first_name || '',
    last_name: initialValues.last_name || '',
    email: initialValues.email || '',
    authorizations: initialValues.authorizations || [],
  };
}

const USER_ROLES = {
  pay: {
    pay_sales: 'Ventas',
    pay_admin: 'Administrador',
    pay_subscription_auditor: 'Auditor de suscripciones',
  },
  card: { viewer: 'Colaborador', admin: 'Administrador' },
  platform: { viewer: 'Colaborador', admin: 'Administrador' },
  match: { viewer: 'Colaborador', admin: 'Administrador' },
};

const PRODUCT_NAMES_TRANSLATIONS = {
  pay: 'Pay',
  card: 'Card',
  platform: 'Platform',
  match: 'Match',
};

export interface Values {
  first_name: string;
  last_name: string;
  email: string;
  authorizations: UserAuthorization[];
}

export interface SettingsUserFormProps {
  initialValues: Values;
  onSubmit: (values: Values) => void;
  submitText: string;
}

const StyledInfoIcon = styled(InfoIcon)`
  margin: 8px 0;
  fill: #ffac30;
`;

const StyledCaption = styled(Caption)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 300px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;

  .user-data-col1 {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .user-data-col2 {
    justify-content: space-between;
    height: 200px;
  }

  .user-authorizations {
    margin-top: 10px;
  }

  .user-authorizations-col1 {
    justify-content: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    .user-data-col1 {
      justify-content: start;
      height: 200px;
    }
  }
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
  }
`;

const ProductsBox = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-color: ${({ theme }) => `${theme.colorsNew.gray.white}`};
    border: 1px solid ${({ theme }) => `${theme.colorsNew.gray.grayMedium}`};
    box-sizing: border-box;
    border-radius: 9px;
    padding: 15px;
  }
`;

export default function SettingsUserForm({
  initialValues,
  onSubmit,
  submitText,
}: SettingsUserFormProps) {
  const { t } = useTranslation('settingsUser');

  const isAdmin = (authorizations: UserAuthorization[]) => {
    const platformRole = authorizations.find(
      (a: UserAuthorization) => a.product_name === 'platform',
    )?.role;
    return platformRole === 'admin';
  };
  return (
    <Formik
      initialValues={sanitizeInitialValues(initialValues)}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={UserSettingsSchema}
    >
      {({
        values,
        isSubmitting,
        isValid,
        handleChange,
        errors,
        touched,
        handleBlur,
        setFieldValue,
      }) => (
        <Form onChange={handleChange}>
          <Row>
            <Column className="user-data-col1" style={{ justifyContent: 'start' }}>
              <H3>Datos del usuario</H3>
              <label style={{ marginTop: 20 }}>
                <input
                  checked={isAdmin(values.authorizations)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const newAuthorizations = values.authorizations.map((a: UserAuthorization) => {
                      if (a.product_name === 'platform') {
                        return {
                          ...a,
                          role: e.target.checked ? 'admin' : 'viewer',
                        };
                      }
                      return a;
                    });
                    setFieldValue('authorizations', newAuthorizations);
                  }}
                  type="checkbox"
                />
                {t('accountAdmin')}
              </label>
              <StyledInfoIcon />
              <StyledCaption>{t('accountAdminCaption')}</StyledCaption>
            </Column>
            <Column className="user-data-col2">
              <InputText
                errorMessage={touched.first_name && errors.first_name}
                id="first_name"
                label={t('firstName')}
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.first_name}
              />
              <InputText
                errorMessage={touched.last_name && errors.last_name}
                id="last_name"
                label={t('lastName')}
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.last_name}
              />
              <div>
                <InputText
                  disabled
                  errorMessage={touched.email && errors.email}
                  id="email"
                  label={t('email')}
                  name="email"
                  required
                  value={values.email}
                />
              </div>
            </Column>
          </Row>
          <ScreenSeparator />
          <Row>
            <Column>
              <H3>Permisos por producto</H3>
            </Column>
            <Column className="user-authorizations">
              <ProductsBox>
                <FieldArray name="authorizations">
                  {({ replace }) => {
                    return (
                      values.authorizations &&
                      values.authorizations.map((a: UserAuthorization, index) => {
                        if (a.product_name === 'platform') return null;
                        return (
                          <Row key={a.product_name} style={{ flexDirection: 'row' }}>
                            <Column className="user-authorizations-col1">
                              <CellText>
                                {
                                  PRODUCT_NAMES_TRANSLATIONS[
                                    a.product_name as keyof typeof PRODUCT_NAMES_TRANSLATIONS
                                  ]
                                }
                              </CellText>
                            </Column>
                            <Column>
                              <NativeSelect
                                label="asd"
                                name={`authorizations.${index}.role`}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                  replace(index, { ...a, role: e.target.value })
                                }
                                value={a.role}
                              >
                                {Object.entries(
                                  USER_ROLES[a.product_name as keyof typeof USER_ROLES],
                                ).map(([role, roleName]) => {
                                  return (
                                    <option key={a.product_name} value={role}>
                                      {roleName}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </Column>
                          </Row>
                        );
                      })
                    );
                  }}
                </FieldArray>
              </ProductsBox>
            </Column>
          </Row>
          <ScreenSeparator />
          <PrimaryButton disabled={isSubmitting || !isValid} type="submit">
            {submitText}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
}
