import React from 'react';
import styled from 'styled-components';

import { Paragraph } from '@increasecard/typed-components';

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  /* TODO(santi698): Check this color with Design Team */
  border-bottom: 1px solid #d0d3de;
  .right {
    display: flex;
    flex-direction: column-reverse;
    a {
      text-decoration: none;
      text-align: center;
      &:last-child {
        margin: 1rem 0;
        line-height: 40px; /* to fix on Design System*/
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .right {
      margin-left: auto;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      a {
        margin-bottom: 0;
        &:last-child {
          margin: 0 0 0 1rem;
        }
      }
    }
  }
`;

const EnterpriseMessage = () => {
  return (
    <React.Fragment>
      <MessageWrapper>
        <Paragraph>
          En esta sección vas encontrar toda la información de tu cuenta y los productos Increase
          que hayas elegido.
          <br />
          <strong>Próximos pasos</strong>
          <br />
          Nuestro equipo se estará poniendo en contacto por mail para agendar una llamada, así
          podemos conocer mejor las necesidades de tu negocio y ofrecerte una mejor integración a
          nuestras soluciones.
          <br />
          Durante el proceso de implementación te acompañaremos paso a paso para cofigurar cada
          detalle en nuestra plataforma
        </Paragraph>
      </MessageWrapper>
    </React.Fragment>
  );
};

export default EnterpriseMessage;
