import { format, parse } from 'date-fns';

interface InvoiceFields {
  id: string;
  number: string;
  emissionDate: Date;
  expirationDate: Date;
  amount: number;
  companyLegalName: string;
  documentLink: string;
  currency: string;
}

export interface InvoiceAPIFields {
  id: string;
  number: string;
  emission_date: string;
  expiration_date: string;
  amount: number;
  company_legal_name: string;
  document_link: string;
  currency: string;
}

export default class Invoice {
  public static API_DATE_FORMAT = 'yyyy-MM-dd';
  public static LOCALIZED_DATE_FORMAT = 'dd/MM/yyyy';

  public id: string;
  public number: string;
  public emissionDate: Date;
  public expirationDate: Date;
  public amount: number;
  public companyLegalName: string;
  public documentLink: string;
  public currency: string;

  constructor({
    id,
    number,
    emissionDate,
    expirationDate,
    amount,
    companyLegalName,
    documentLink,
    currency,
  }: InvoiceFields) {
    this.id = id;
    this.number = number;
    this.emissionDate = emissionDate;
    this.expirationDate = expirationDate;
    this.amount = amount;
    this.companyLegalName = companyLegalName;
    this.documentLink = documentLink;
    this.currency = currency;
  }

  public get displayEmissionDate() {
    return format(this.emissionDate, Invoice.LOCALIZED_DATE_FORMAT);
  }

  public get displayExpirationDate() {
    return format(this.expirationDate, Invoice.LOCALIZED_DATE_FORMAT);
  }

  public static fromAPI({
    id,
    number,
    emission_date: emissionDate,
    expiration_date: expirationDate,
    amount,
    company_legal_name: companyLegalName,
    document_link: documentLink,
    currency,
  }: InvoiceAPIFields) {
    return new Invoice({
      id,
      number,
      emissionDate: parse(emissionDate, Invoice.API_DATE_FORMAT, new Date()),
      expirationDate: parse(expirationDate, Invoice.API_DATE_FORMAT, new Date()),
      amount,
      companyLegalName,
      documentLink,
      currency,
    });
  }
}
