/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainContent, ContentWrapper } from '../components/Containers';
import SectionTitle from '../components/SectionTitle';
import SettingsSidebar from '../components/SettingsSidebar';
import SettingsUserForm from '../components/SettingsUserForm';
import { useUserSettings } from '../hooks/useUserSettings';
import LoadingIndicator from '../components/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import InlineAlert from '../components/InlineAlert';
import { BackButton } from '../components/Buttons';
import { ScreenSeparator } from '../components/common/ScreenSeparator';
import { useHistory } from 'react-router-dom';
import { ApiErrorMessage } from '../components/ApiErrorMessage';

function SettingsUser() {
  const [apiError, setApiError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { t } = useTranslation('settingsUser');
  const { id } = useParams();
  const { userSettings, updateUserSettings } = useUserSettings(id);
  const history = useHistory();

  const onSubmit = async (values) => {
    try {
      setApiError(null);
      setSuccessMessage(null);

      await updateUserSettings(userSettings.id, values);

      setSuccessMessage(t('savedSuccessfully'));
      setApiError(null);
    } catch (error) {
      setApiError(error);
      setSuccessMessage(null);
    }
  };

  function backToUsersSettings() {
    history.push(`/advanced/settings/users`);
  }

  function onSuccessMessageClose() {
    setSuccessMessage(null);
  }

  const loadingUser = userSettings === null;

  return (
    <ContentWrapper>
      <SettingsSidebar settings="advanced" />
      <MainContent>
        <BackButton label={t('back')} onClick={backToUsersSettings} />
        {loadingUser ? (
          <LoadingIndicator />
        ) : (
          <>
            <SectionTitle style={{ marginTop: 20 }}>
              {t('title', {
                first_name: userSettings.first_name,
                last_name: userSettings.last_name,
              })}
            </SectionTitle>
            <ScreenSeparator />
            <SettingsUserForm
              initialValues={userSettings}
              onSubmit={onSubmit}
              submitText={t('submitForm')}
            />
          </>
        )}
        {successMessage && (
          <InlineAlert
            message={successMessage}
            onClose={onSuccessMessageClose}
            style={{ marginTop: '40px' }}
            variant="info"
          />
        )}
        <ApiErrorMessage error={apiError} marginTop={40} />
      </MainContent>
    </ContentWrapper>
  );
}

export default SettingsUser;
