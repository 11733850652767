import axios from 'axios';
import config from '../config';
import { PayPlan } from '../models/PayPlan';

const client = axios.create({
  baseURL: config.payApiUrl,
  withCredentials: true,
});
export default client;

export function getEnabledPlans(productId: string): Promise<PayPlan[]> {
  return client.get(`/checkout/v1/products/${productId}/plans?status=enabled`).then((response) => {
    const plans = response.data.data;
    return plans;
  });
}
