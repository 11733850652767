import React from 'react';
import { Button } from '@increasecard/typed-components';

import { ContentWrapper } from '../components/Containers';
import UsefulResources from '../components/UsefulResources';
import { ColumnTitle } from '../components/ColumnTitle';
import InvoiceDetailTable from '../components/InvoiceDetailTable';
import styled from 'styled-components';
import { useCountry, useProducts, useUser } from '@increasecard/increase-core';
import usePriceToPay from '../hooks/usePriceToPay';
import LoadingIndicator from '../components/LoadingIndicator';
import usePaymentRequired from '../hooks/usePaymentRequired';

const propTypes = {};

const defaultProps = {};

function TrialEndSuccess({ className, productCodename = 'card' }) {
  const user = useUser();
  const country = useCountry();
  const quantity = user.productQuantity(productCodename, user && user.defaultCountry);
  const priceToPay = usePriceToPay({ productName: productCodename, country, units: quantity });
  const paymentRequired = usePaymentRequired();
  const products = useProducts();

  if (products === null || user === null || (paymentRequired && priceToPay === null)) {
    return <LoadingIndicator />;
  }

  const product = products.find((product) => product.name === productCodename);

  return (
    <ContentWrapper as="section" className={className}>
      <header>
        <h1 style={{ fontSize: '28px' }}>
          ¡Que bueno que empecemos a potenciar juntos tu negocio!
        </h1>
        <div>
          <Button as="a" href={product.uri}>
            Regresar a {product.display_name}
          </Button>
        </div>
      </header>
      <main>
        {paymentRequired && (
          <React.Fragment>
            <h2>Resumen</h2>
            <InvoiceDetailTable
              quantity={priceToPay.quantity}
              totalPrice={priceToPay.total}
              unitPrice={priceToPay.unit_price}
            />
            <p>
              Te recordamos que el débito del servicio es mensual y tu primer factura será enviada
              en un mes a partir de ahora.
            </p>
            <p>
              Desde la configuración de tu cuenta vas a poder acceder a tus facturas, dar de alta
              nuevas terminales, otros productos o cancelar el servicio.
            </p>
          </React.Fragment>
        )}
        <section style={{ marginTop: '56px' }}>
          <ColumnTitle>Recursos Útiles</ColumnTitle>
          <UsefulResources />
        </section>
      </main>
    </ContentWrapper>
  );
}

TrialEndSuccess.propTypes = propTypes;
TrialEndSuccess.defaultProps = defaultProps;

export default styled(TrialEndSuccess)`
  padding: 0;
  flex-direction: column;

  header {
    background-color: rgba(77, 224, 126, 0.35);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
  }

  main {
    padding: 16px 0;
    max-width: 800px;
    margin: 0 auto;
  }
`;
