import React, { useEffect } from 'react';
import { Currency, Table, Td, TableRow, Th, THead } from '@increasecard/typed-components';
import styled from 'styled-components';
import SectionTitle from '../components/SectionTitle';
import { ContentWrapper } from '../components/Containers';
import FriendlyDateTime from '../components/FriendlyDateTime';
import { capitalize } from '../services/Strings';
import { useTranslation } from 'react-i18next';
import usePayments from '../hooks/usePayments';

const MainContent = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 700px;
  }
`;

const TableStyled = styled(Table)`
  border-collapse: collapse;
  font-size: 13px;

  th {
    text-transform: uppercase;
    color: black;
    font-size: 12px;
    letter-spacing: 0.06em;
    padding: 18px 10px;
  }

  td {
    border-bottom: 1px solid #eee;
  }
`;

function Payments() {
  const { t } = useTranslation('payments');
  const [payments, fetchPayments] = usePayments();

  useEffect(() => {
    if (payments.length === 0) return;
    const timeoutId = setTimeout(fetchPayments, 2000);
    // eslint-disable-next-line consistent-return
    if (timeoutId) return () => clearTimeout(timeoutId);
  }, [payments, fetchPayments]);

  return (
    <ContentWrapper style={{ justifyContent: 'center' }}>
      <SectionTitle>Tus Pagos</SectionTitle>
      <MainContent>
        {payments.length === 0 ? (
          <p>No hay pagos realizados</p>
        ) : (
          <TableStyled>
            <THead>
              <TableRow>
                <Th>Estado</Th>
                <Th>Medio de pago</Th>
                <Th>Procesador de pago</Th>
                <Th>Fecha y Hora</Th>
                <Th>Importe</Th>
              </TableRow>
            </THead>
            <tbody>
              {payments.map((payment) => (
                <TableRow
                  key={payment}
                  style={{
                    color: ['rejected', 'reversed'].includes(payment.status)
                      ? '#b34950'
                      : 'initial',
                  }}
                >
                  <Td>{t(`paymentStatus.${payment.status}`)}</Td>
                  <Td>
                    {`${payment.payment_method.metadata.franchise_name} terminada en ${payment.payment_method.metadata.last_digits}`}
                  </Td>
                  <Td>{capitalize(payment.metadata.type)}</Td>
                  <Td>
                    <FriendlyDateTime date={new Date(payment.created_at)} />
                  </Td>
                  <Td>
                    <Currency currency="USD" language="es-EC" value={payment.amount} />
                  </Td>
                </TableRow>
              ))}
            </tbody>
          </TableStyled>
        )}
      </MainContent>
    </ContentWrapper>
  );
}

export default Payments;
