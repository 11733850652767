import * as Yup from 'yup';

export default Yup.object({
  legal_name: Yup.string().required('Campo obligatorio'),
  tax_id: Yup.string().matches(/^\d*$/, 'Debe contener sólo números').required('Campo obligatorio'),
  address: Yup.object({
    country: Yup.string().min(1).required('Campo obligatorio'),
    province: Yup.string().min(1).required('Campo obligatorio'),
    street: Yup.string().required('Campo obligatorio'),
    postal_code: Yup.string().required('Campo obligatorio'),
  }),
  metadata: Yup.object({
    tax_condition: Yup.string()
      .oneOf(['RESPONSABLE_INSCRIPTO', 'EXENTO', 'CONSUMIDOR_FINAL', 'MONOTRIBUTO'])
      .required('Campo obligatorio'),
  }),
  email: Yup.string().email('Email incorrecto').required('Campo obligatorio'),
  active_payment_method: Yup.object({
    metadata: Yup.object({
      number: Yup.string()
        .length(22, 'Debe tener 22 dígitos')
        .matches(/^\d+$/, 'Debe contener solo números')
        .required('Campo obligatorio'),
    }),
  }),
});
