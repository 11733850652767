import { useState, useCallback } from 'react';
import { getEnabledPlans } from '../../services/payAPI';
import { PayPlan } from '../../models/PayPlan';
import { config } from '../../config';

export function usePayPlans(): [PayPlan[], () => void] {
  const [plans, setPlans] = useState<PayPlan[]>([]);

  function filterCustomPlan(plans: PayPlan[]) {
    // Custom plan can't be selected on Self On-Boarding flow
    return plans.filter((plan) => {
      return plan.id !== 'ecf6d5b8-e423-41db-923a-d0bf4ad31713';
    });
  }

  const fetchPlans = useCallback(() => {
    getEnabledPlans(config.payProductId).then(filterCustomPlan).then(setPlans);
  }, []);

  return [plans, fetchPlans];
}
