/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Grid } from '@increasecard/typed-components';
import { MainContent, ContentWrapper } from '../components/Containers';
import SectionTitle from '../components/SectionTitle';
import SettingsSidebar from '../components/SettingsSidebar';
import LoadingIndicator from '../components/LoadingIndicator';
import { ApiErrorMessage } from '../components/ApiErrorMessage';
import useAvailablePaymentMethodTypes from '../hooks/useAvailablePaymentMethodTypes';
import usePaymentMethods from '../hooks/usePaymentMethods';
import useToggle from '../hooks/useToggle';
import PaymentMethodEditForm from '../components/PaymentMethods/PaymentMethodEditForm';
import PaymentMethodCreateForm from '../components/PaymentMethods/PaymentMethodCreateForm';
import { updatePaymentMethod, createPaymentMethod } from '../services/PaymentMethodsService';

function SettingsPaymentMethods() {
  const [apiError, setApiError] = useState(null);
  const { t } = useTranslation('settingsPaymentMethods');
  const [paymentMethods, fetchPaymentMethods] = usePaymentMethods();
  const availablePaymentMethods = useAvailablePaymentMethodTypes();
  const { value: adding, toggle: toggleAdding } = useToggle();

  const handleUpdatePaymentMethod = async (paymentMethod) => {
    setApiError(null);
    try {
      await updatePaymentMethod(paymentMethod);
      await fetchPaymentMethods();
    } catch (error) {
      setApiError(error);
    }
  };

  const handleCreatePaymentMethod = async (paymentMethod) => {
    try {
      await createPaymentMethod(paymentMethod);
      await fetchPaymentMethods();
      toggleAdding();
    } catch (error) {
      setApiError(error);
    }
  };

  const handleMakeActive = async (paymentMethod) => {
    await updatePaymentMethod({ id: paymentMethod.id, type: paymentMethod.type, active: true });
    await fetchPaymentMethods();
  };

  if (paymentMethods?.length === 0 && !adding) toggleAdding();

  const loadingPaymentMethods = paymentMethods === null || availablePaymentMethods === null;
  return (
    <ContentWrapper>
      <SettingsSidebar settings="invoices" />
      <MainContent>
        <SectionTitle>{t('pageTitle')}</SectionTitle>
        <p>{t('pageDescription')}</p>
        <Grid rowGap={3}>
          <ApiErrorMessage error={apiError} marginTop={8} />
          {loadingPaymentMethods ? (
            <LoadingIndicator />
          ) : (
            paymentMethods.map((paymentMethod) => (
              <PaymentMethodEditForm
                availablePaymentMethods={availablePaymentMethods}
                key={paymentMethod.id}
                onMakeActive={handleMakeActive}
                onSubmit={handleUpdatePaymentMethod}
                paymentMethod={paymentMethod}
              />
            ))
          )}
          <div style={{ marginTop: '24px' }}>
            {adding ? (
              loadingPaymentMethods ? (
                <LoadingIndicator />
              ) : (
                <PaymentMethodCreateForm
                  availablePaymentMethods={availablePaymentMethods}
                  onCancel={toggleAdding}
                  onSubmit={handleCreatePaymentMethod}
                />
              )
            ) : (
              <Link
                as="button"
                onClick={toggleAdding}
                style={{
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                  padding: '4px 0',
                  textDecoration: 'underline',
                }}
                type="button"
              >
                {t('addAnotherPaymentMethod')}
              </Link>
            )}
          </div>
        </Grid>
      </MainContent>
    </ContentWrapper>
  );
}

export default SettingsPaymentMethods;
