import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Button, Link, Grid } from '@increasecard/typed-components';

import useToggle from '../../hooks/useToggle';
import PaymentMethodInputGroup from './PaymentMethodInputGroup';
import ButtonGroup from '../ButtonGroup';
import PaymentMethodSchema from '../../schemas/PaymentMethodSchema';
import PaymentMethodShow from './PaymentMethodShow';
import styled from 'styled-components';

const propTypes = {
  availablePaymentMethods: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  paymentMethod: PropTypes.object,
};

const defaultProps = {};

const ContentCard = styled.div`
  background: ${({ theme }) => theme.colorsNew.gray.white};
  border-radius: 6px;
  box-shadow: 0px 1px 3px rgba(24, 32, 38, 0.09), 0px 2px 2px rgba(24, 32, 38, 0.06),
    0px 3px 2px rgba(24, 32, 38, 0.07);

  &:hover {
    box-shadow: 0px 8px 10px rgba(24, 32, 38, 0.09), 0px 6px 30px rgba(24, 32, 38, 0.11),
      0px 16px 24px rgba(24, 32, 38, 0.13);
  }
`;

const ContentCardBody = styled.div`
  padding: 12px 16px;
`;

const ContentCardHeader = styled.div`
  padding: 12px 14px;
  background: ${({ theme }) => theme.colorsNew.gray.grayLight};
`;

export default function PaymentMethodEditForm({
  availablePaymentMethods,
  paymentMethod,
  onSubmit,
  onMakeActive,
}) {
  const { value: editing, toggle: toggleEditing } = useToggle();
  const editable = paymentMethod.type !== 'PaymentMethods::Placetopay';
  if (!editing) {
    return (
      <Grid as={ContentCard} rowGap={3}>
        <ContentCardHeader>
          {!editing && (
            <ButtonGroup>
              {editable && (
                <Link
                  as="button"
                  onClick={toggleEditing}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    cursor: 'pointer',
                    padding: '4px 0',
                    textDecoration: 'underline',
                  }}
                  type="button"
                >
                  Editar
                </Link>
              )}
              {paymentMethod.active ? (
                <strong>Medio de pago principal</strong>
              ) : (
                <Link
                  as="button"
                  onClick={() => {
                    onMakeActive(paymentMethod);
                  }}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    cursor: 'pointer',
                    padding: '4px 0',
                    textDecoration: 'underline',
                  }}
                  type="button"
                >
                  Establecer como medio de pago principal
                </Link>
              )}
            </ButtonGroup>
          )}
        </ContentCardHeader>
        <ContentCardBody>
          <PaymentMethodShow paymentMethod={paymentMethod} />
        </ContentCardBody>
      </Grid>
    );
  }
  return (
    <Formik
      allowReinitialize={true}
      initialValues={paymentMethod}
      key={paymentMethod.id}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
        toggleEditing();
      }}
      validationSchema={PaymentMethodSchema}
    >
      {({ handleChange, values, setFieldValue, isSubmitting, isValid }) => (
        <Form
          onChange={(e) => {
            if (e.target.name === 'type') {
              setFieldValue('metadata', {});
            }
            handleChange(e);
          }}
        >
          <ContentCard>
            {!editing && (
              <ContentCardHeader>
                <ButtonGroup>
                  <Link
                    as="button"
                    onClick={toggleEditing}
                    style={{
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer',
                      padding: '4px 0',
                      textDecoration: 'underline',
                    }}
                    type="button"
                  >
                    Editar
                  </Link>
                  {values.active ? (
                    <strong>Medio de pago principal</strong>
                  ) : (
                    <Link
                      as="button"
                      onClick={() => {
                        setFieldValue('active', true);
                        onMakeActive(values);
                      }}
                      style={{
                        border: 'none',
                        background: 'transparent',
                        cursor: 'pointer',
                        padding: '4px 0',
                        textDecoration: 'underline',
                      }}
                      type="button"
                    >
                      Establecer como medio de pago principal
                    </Link>
                  )}
                </ButtonGroup>
              </ContentCardHeader>
            )}
            <ContentCardBody>
              <PaymentMethodInputGroup
                availablePaymentMethods={availablePaymentMethods}
                method={values}
                readOnly={!editing}
              />
              {editing && (
                <ButtonGroup>
                  <Button disabled={isSubmitting || !isValid} type="submit">
                    Guardar
                  </Button>
                  <Button buttonType="invisible" onClick={toggleEditing}>
                    Cancelar
                  </Button>
                </ButtonGroup>
              )}
            </ContentCardBody>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
}

PaymentMethodEditForm.propTypes = propTypes;
PaymentMethodEditForm.defaultProps = defaultProps;
