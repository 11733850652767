import React, { useState, useEffect } from 'react';
import { MainContent, ContentWrapper } from '../components/Containers';
import SectionTitle from '../components/SectionTitle';
import SettingsSidebar from '../components/SettingsSidebar';
import { Paragraph } from '@increasecard/typed-components';
import {
  createInvitation,
  getInvitations,
  resendInvitation,
  verifyEmail,
  removeInvitation,
} from '../services/InvitationService';
import UserInviteForm from '../components/UserInviteForm';
import LoadingIndicator from '../components/LoadingIndicator';
import { UsersTable } from './UsersTable';
import InlineAlert from '../components/InlineAlert';
import { useUser } from '@increasecard/increase-core';
import { useTranslation } from 'react-i18next';
import { ApiErrorMessage } from '../components/ApiErrorMessage';

function useInvitations() {
  const [invitations, setInvitations] = useState(null);
  useEffect(() => {
    getInvitations().then(setInvitations);
  }, []);
  return { invitations, setInvitations };
}

export default function SettingsUsers() {
  const [apiError, setApiError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const activeUser = useUser();
  const { invitations, setInvitations } = useInvitations();
  const { t } = useTranslation('settingsUsers');

  if (activeUser === null) return <LoadingIndicator />;

  const loadingInvitations = invitations === null;

  const inviteUser = (inviteForm) => {
    if (verifyEmail(inviteForm.email)) {
      createInvitation(inviteForm)
        .then(() => {
          setSuccessMessage(`La invitación a ${inviteForm.email} ha sido enviada.`);
        })
        .catch((error) => {
          setApiError(error);
        });
    } else {
      setValidationError('El email es incorrecto');
    }
  };
  const handleResendInvitation = (invitation) => {
    resendInvitation(invitation.id)
      .then(() => {
        setSuccessMessage(`La invitación a ${invitation.email} ha sido reenviada.`);
      })
      .catch((error) => {
        setApiError(error);
      });
  };

  const handleRemoveInvitation = (invitation) => {
    removeInvitation(invitation.id)
      .then(async () => {
        const invitations = await getInvitations();
        setInvitations(invitations);
        setSuccessMessage(`Se eliminó la invitación a ${invitation.email}`);
      })
      .catch((error) => {
        setApiError(error);
      });
  };

  const userCanInvite = ['superuser', 'admin'].includes(activeUser.role()) || activeUser.owner;
  return (
    <ContentWrapper>
      <SettingsSidebar settings="advanced" />
      <MainContent>
        <SectionTitle>{t('title')}</SectionTitle>
        <Paragraph>{t('description')}</Paragraph>
        {validationError && (
          <InlineAlert
            message={validationError}
            onClose={() => setValidationError(null)}
            style={{ margin: '15px 0' }}
            variant="alert"
          />
        )}
        {successMessage && (
          <InlineAlert
            message={successMessage}
            onClose={() => setSuccessMessage(null)}
            style={{ margin: '15px 0' }}
            variant="info"
          />
        )}
        <ApiErrorMessage error={apiError} marginTop={15} />
        {userCanInvite ? <UserInviteForm onSubmit={inviteUser} /> : null}
        <div style={{ marginTop: '15px' }}>
          {loadingInvitations ? (
            <LoadingIndicator />
          ) : (
            <UsersTable
              activeUser={activeUser}
              activeUserIsAdmin={userCanInvite}
              invitations={invitations}
              onInvitationResend={handleResendInvitation}
              onRemoveInvitation={handleRemoveInvitation}
            />
          )}
        </div>
      </MainContent>
    </ContentWrapper>
  );
}
