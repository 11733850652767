import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
}

function RadioButton({ className, label, name, value, onChange, ...rest }: RadioButtonProps) {
  return (
    <label className={className}>
      <input name={name} onChange={onChange} type="radio" value={value} {...rest} />
      <div className="overlay">{label}</div>
    </label>
  );
}

export default styled(RadioButton)`
  z-index: 0;
  position: relative;
  display: inline-flex;
  height: 40px;
  width: 40px;

  input {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  input + .overlay {
    content: '${(props) => props.label}';
    z-index: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  input:checked + .overlay {
    background-color: #27ba58;
    border-color: #27ba58;
    color: #ffffff;
  }
`;
