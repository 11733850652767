import client from './platformAPI';
import Invoice, { InvoiceAPIFields } from '../models/Invoice';
import { Payment } from '../models/Payment';
import { Company } from '../models/Company';

export function getInvoices(): Promise<Invoice[]> {
  return client
    .get('/v1/invoices')
    .then((response) =>
      response.data.data.map((invoice: InvoiceAPIFields) => Invoice.fromAPI(invoice)),
    );
}

export function getPayments(): Promise<Payment> {
  return client
    .get('/v1/payments', { headers: { Tenant: 'ecuador' } })
    .then((response) => response.data);
}

export function updateBillingSettings(
  values: Company,
  country: string,
  overwriteTaxId = false,
): Promise<void> {
  values['overwrite_tax_id_account'] = overwriteTaxId;
  return client.post('/v1/billing_settings', values, {
    headers: { tenant: country },
  });
}
